import React from "react";
import { Route } from "react-router";
import { FeatureConfiguration } from "app/featureConfig";

export default function (config: FeatureConfiguration) {
    config.routes.locationRoutes.push(
        <Route
            path="/:region"
            exact
            key="home"
            render={() => (
                null
            )}
        />
    );
}
