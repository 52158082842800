import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./Spinner.scss";

interface Props {
    size?: "small" | "default" | "large" | number;
}

export const Spinner = ({ size }: Props) => {
    let fontSize = 24;
    switch (size) {
        case "small":
            fontSize = 14;
            break;
        case "default":
            fontSize = 24;
            break;
        case "large":
            fontSize = 66;
            break;
        default:
            fontSize = size || 24;
    }
    return <Spin indicator={<LoadingOutlined style={{ fontSize }} spin translate={null} />} />;
};
