import React, { useEffect, useMemo } from "react";
import { NetworkViewer } from 'network-viewer';
import { State } from '../types'
import { Spinner } from "sharedComponents";

import "./PosHttpArchivePage.scss";

export interface Props {
    operationId: string,
    har: State['har'],
    load: (operationId: string) => void;
}

export const PosHttpArchivePage = ({
    operationId,
    har,
    load
}: Props) => {
    useEffect(() => {
        load(operationId);
    }, [operationId, load]);

    const data = useMemo(() => {

        if (har.status === "loaded") {
            // har.data is immutable because of redux toolkit
            // but NetworkViewer mutates (sorts) data within it
            return JSON.parse(JSON.stringify(har.data));
        }

        return null;

    }, [har])
    
    return (
        har.status === "loaded"
            ? renderHttpArchive(data) :
        har.status === "failed"
            ? <>Failed</>
            : <Spinner />
    );
};

function renderHttpArchive(data: any) {
    if (data?.log?.entries?.length > 0) {
        return (
            <NetworkViewer data={data} containerClassName="network-viewer-container" />
        );
    }

    return <>No entries found</>;
}