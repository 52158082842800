import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loaded, LoadStatus } from "common/loader";
import { Region } from "../types/region";

export type State = Loaded<Region[]>;

const initialState: State = {
    status: "unloaded",
};

export const regionsSlice = createSlice({
    name: "activeRegions",
    initialState,
    reducers: {
        loadingRegions: (state: State, action: PayloadAction<LoadStatus>) => {
            state.status = action.payload;
            if (state.status === "failed") {
                state.error = "failed to load";
            }
        },
        addRegion: (state: State, action: PayloadAction<Region>) => {
            state.status = "loaded";
            if (state.status === "loaded") {
                if (!state.data) {
                    state.data = [action.payload];
                } else if (
                    state.data.every(
                        (r: Region) => r.id !== action.payload.id && r.displayName !== action.payload.displayName
                    )
                ) {
                    state.data = [...state.data, action.payload];
                }
            }
        },
    },
});

export const actionCreators = regionsSlice.actions;
export const reducer = regionsSlice.reducer;
