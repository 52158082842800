import React from "react";
import { OrderItem } from "../types";
import { Text, Section, Spinner, Currency, Divider } from "sharedComponents";
import "../assets/OrderItemsSection.scss";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import { NoData } from "./NoData";

export interface Props {
    items?: OrderItem[];
    memberId?: string;
    locale?: string;
    currency?: string;
    total?: number;
    singleUser?: boolean;
    loading?: boolean;
}

const ItemDetails = ({
    item,
    locale,
    currency,
    isMember,
    singleUser,
}: {
    item: OrderItem;
    locale: string;
    currency: string;
    isMember: boolean;
    singleUser?: boolean;
}) => (
    <>
        {!singleUser && item.member?.displayName && (
            <Text
                className="order-items--row--member-name"
                mode={!isMember ? "label" : undefined}
                value={item.member.displayName}
            />
        )}
        <Text className="order-items--row--price">
            <Currency locale={locale} currency={currency} value={item.lineTotal || item.unitPrice || 0} />
        </Text>
    </>
);

export const OrderItemsSection = ({ loading, items, memberId, locale, currency, total, singleUser }: Props) => {
    return (
        <Section className="order-items">
            {!items || !items.length || !memberId ? (
                <NoData title="Order" />
            ) : (
                <>
                    <Text className="order-items--header" preset="header" mode="label" value="Order" />
                    {!!loading ? (
                        <Spinner size="large" />
                    ) : (
                        <>
                            {items.map((item, index) => {
                                return (
                                    <div key={`order_row_item_${item.sku}_${index}`} className="order-items--row">
                                        <KeyValueRow
                                            className={item.failedToSubmit ? "failed" : ""}
                                            key={`order_item_${item.sku}_${index}`}
                                            mode={item.member?.id === memberId ? "emphasis" : undefined}
                                            item={{
                                                label: (
                                                    <>
                                                        <Text className="order-items--row--quantity">
                                                            {item.quantity}x
                                                        </Text>
                                                        <Text mode="bold" value={item.displayName} />
                                                        &nbsp;{item.variantName ? `/ ${item.variantName}` : ""}
                                                    </>
                                                ),
                                                value: (
                                                    <ItemDetails
                                                        item={item}
                                                        locale={locale!}
                                                        currency={currency!}
                                                        isMember={item.member?.id === memberId}
                                                        singleUser={singleUser}
                                                    />
                                                ),
                                            }}
                                        />
                                        <>
                                            {!!item.modifiers &&
                                                item.modifiers.map((m, mIndex) => (
                                                    <div key={`item_modifier_${item.sku}_${m.sku}_${mIndex}_${index}`}>
                                                        <KeyValueRow
                                                            className={item.failedToSubmit ? "failed" : ""}
                                                            key={`order_modifier_${m.sku}_${mIndex}_${index}`}
                                                            item={{
                                                                label: (
                                                                    <>
                                                                        <Text
                                                                            className="order-items--row--quantity"
                                                                            value=""
                                                                        />
                                                                        {m.displayName}
                                                                    </>
                                                                ),
                                                                value: (
                                                                    <>
                                                                        (
                                                                        <Currency
                                                                            locale={locale!}
                                                                            currency={currency!}
                                                                            value={m.unitPrice}
                                                                        />
                                                                        )
                                                                    </>
                                                                ),
                                                            }}
                                                        />
                                                        {m.nestedModifiers &&
                                                            m.nestedModifiers.map((nestedModifier, nIndex) => (
                                                                <KeyValueRow
                                                                    className={item.failedToSubmit ? "failed" : ""}
                                                                    key={`order_nested_modifier_${m.sku}_${mIndex}_${index}_${nIndex}`}
                                                                    item={{
                                                                        label: (
                                                                            <>
                                                                                <Text
                                                                                    className="order-items--row--quantity"
                                                                                    value=""
                                                                                />
                                                                                – {nestedModifier.displayName}
                                                                            </>
                                                                        ),
                                                                        value: (
                                                                            <>
                                                                                (
                                                                                <Currency
                                                                                    locale={locale!}
                                                                                    currency={currency!}
                                                                                    value={nestedModifier.unitPrice}
                                                                                />
                                                                                )
                                                                            </>
                                                                        ),
                                                                    }}
                                                                />
                                                            ))}
                                                    </div>
                                                ))}
                                            {item.memberNotes && (
                                                <KeyValueRow
                                                    className={item.failedToSubmit ? "failed" : ""}
                                                    mode="mild"
                                                    key={`order_item_note_${item.sku}_${index}`}
                                                    item={{ label: `Note: ${item.memberNotes}` }}
                                                />
                                            )}
                                        </>
                                        <Divider key={`order_item_divider_${item.sku}_${index}`} />
                                    </div>
                                );
                            })}
                            <KeyValueRow
                                mode="emphasis"
                                item={{
                                    label: "Total order amount",
                                    value: <Currency locale={locale!} currency={currency!} value={total!} />,
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </Section>
    );
};
