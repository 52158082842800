import React from "react";
import classNames from "classnames";
import "./Text.scss";

export type TextModes = "" | "bold" | "link" | "label" | "block";

interface baseProp {
    /**
     * ""|"bold"|"link"|"disabled"
     */
    mode?: TextModes | TextModes[];
    /**
     * "title"|"subtitle"|"body"|"header"
     */
    preset?: "title" | "subtitle" | "body" | "header" | "info-title";
    disabled?: boolean;
    className?: string;
}

interface optionalChild extends baseProp {
    children: React.ReactNode;
}
interface optionalValue extends baseProp {
    value: string;
}

type Props = optionalChild | optionalValue;

export const Text = (props: Props) => {
    const child = !!(props as optionalValue).value ? (props as optionalValue).value : (props as optionalChild).children;
    const { mode, preset, disabled, className } = props;
    const modClass = !mode
        ? undefined
        : Array.isArray(mode)
        ? mode.map((m) => `mode--${m}`).join(" ")
        : `mode--${mode}`;
    return (
        <span
            className={classNames("text", modClass, preset || "body", disabled && "disabled", className && className)}
        >
            {child}
        </span>
    );
};
