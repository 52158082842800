import React, { useEffect } from "react";
import { Spinner, DateFormatted, Input, Text, DateRange, Button, RadioButtons } from "sharedComponents";
import { LocationPartyData, LocationPartiesFilter } from "..";
import { Tables } from "sharedComponents/dataDisplay/tables";
import { LayoutType, FourFields } from "sharedComponents/dataDisplay/tables/types";
import { RowOrderId } from "./RowOrderId";
import "../assets/LocationParties.scss";

export interface Props {
    locationId: string;
    totalCount: number;
    displayPage: number;
    loading: boolean;
    selectedParty?: string;
    parties: LocationPartyData[];
    filters?: LocationPartiesFilter;
    getLocationParties: (locationId: string, skip: number, limit: number, filters?: LocationPartiesFilter) => void;
    goToPage: (page: number) => void;
    showParty: (partyId: string, memberId?: string) => void;
    setDatesFilter: (from: string, to: string) => void;
    removeDatesFilter: () => void;
    setOrderFilter: (orderId: string) => void;
    onSearch: () => void;
    onActiveChange: (active: boolean) => void;
    activeTab: number;
}

const columnData = [
    {
        title: "Member",
        render: (memberName: string) => (
            <span className={memberName === "Guest" ? "location-parties--row--item-disabled" : ""}>{memberName}</span>
        ),
    },
    {
        title: "Order ID",
        render: (order: string, item: FourFields) => <RowOrderId order={order} orderData={item.originalData} />,
    },
    {
        title: "Date",
        render: (date: string) => <DateFormatted value={date} />,
    },
    {
        title: "Payment",
    },
];

const ROWS_PER_PAGE = 7;

export const LocationParties = ({
    parties,
    loading,
    onSearch,
    onActiveChange,
    displayPage,
    locationId,
    totalCount,
    getLocationParties,
    goToPage,
    showParty,
    selectedParty,
    setDatesFilter,
    removeDatesFilter,
    setOrderFilter,
    filters,
    activeTab,
}: Props) => {
    useEffect(() => {
        if (activeTab === 0) {
            getLocationParties(locationId, ROWS_PER_PAGE * (displayPage - 1), ROWS_PER_PAGE, filters);
        }
        // Otherwise this fires on every filters change, but the requirement is to fire this on page load and pagination/search button click.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLocationParties, locationId, displayPage, activeTab]);

    let partyData = parties;
    if (selectedParty) {
        const selectedPartyIndex = parties.findIndex((party) => party.id === selectedParty);
        if (selectedPartyIndex < 3) {
            partyData = parties.slice(0, 5);
        } else if (selectedPartyIndex > parties.length - 3) {
            partyData = parties.slice(parties.length - 5);
        } else {
            partyData = parties.slice(selectedPartyIndex - 2, Math.min(selectedPartyIndex + 3, parties.length));
        }
    }

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        setOrderFilter(e.target.value);
    }
    function onDateSelect(from: string, to: string) {
        if (!from || !to) {
            removeDatesFilter();
            return;
        }
        setDatesFilter(from, to);
    }

    return (
        <>
            {loading && !partyData.length ? (
                <Spinner size="large" />
            ) : (
                <div className="location-parties">
                    <div className="info-section__title">
                        <Text preset="header" mode="label" value="Recent Orders" />
                    </div>
                    <div className="location-parties--inputs">
                        <Input
                            className="location-parties--input location-parties--filter"
                            defaultValue={filters?.orderId || undefined}
                            allowClear
                            placeholder="Order ID"
                            onChange={onChange}
                        />
                        <DateRange
                            className="location-parties--input location-parties--filter location-parties--date-range"
                            defaultValues={filters?.dates ? [filters.dates.from, filters.dates.to] : undefined}
                            onSelect={onDateSelect}
                        />
                        <RadioButtons
                            className="location-parties--input location-parties--filter"
                            handleChange={onActiveChange}
                            selectedValue={filters?.active || false}
                            items={[
                                { value: false, label: "Closed" },
                                { value: true, label: "Active" },
                            ]}
                        />
                        <Button className="location-parties--filter" search onClick={() => onSearch()} value="Search" />
                    </div>
                    <Tables
                        layoutType={selectedParty ? LayoutType.THREE_COLUMNS : LayoutType.FOUR_COLUMNS}
                        dataSource={partyData}
                        selected={selectedParty}
                        tableTitle=""
                        columnData={columnData}
                        startColumn={selectedParty ? 1 : 0}
                        goToPage={goToPage}
                        page={displayPage}
                        selectRow={(row) => showParty(row.originalData?.id, row.originalData?.memberId)}
                        pageSize={parties.length > ROWS_PER_PAGE ? parties.length : ROWS_PER_PAGE}
                        showPagination={!selectedParty}
                        totalCount={totalCount}
                    />
                </div>
            )}
        </>
    );
};
