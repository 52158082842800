import React, { useEffect } from "react";
import {
    Spinner,
    DateFormatted,
    Input,
    DateRange,
    Button,
    RadioButtons,
    StartandEndDateFormatted,
} from "sharedComponents";
import { LocationGroupTabData, LocationGroupTabsFilter } from "..";
import { Tables } from "sharedComponents/dataDisplay/tables";
import { FourFields, LayoutType } from "sharedComponents/dataDisplay/tables/types";
import "../assets/LocationGroupTabs.scss";
import moment from "moment";

export interface Props {
    locationId: string;
    totalCount: number;
    displayPage: number;
    loading: boolean;
    selectedGroupTab?: string;
    groupTabs: LocationGroupTabData[];
    groupTabfilters?: LocationGroupTabsFilter;
    getLocationGroupTabs: (
        locationId: string,
        skip: number,
        limit: number,
        groupTabfilters?: LocationGroupTabsFilter
    ) => void;
    goToPage: (page: number) => void;
    showGroupTab: (groupTabId: string) => void;
    setDatesFilter: (from: string, to: string) => void;
    removeDatesFilter: () => void;
    setOwnerFilter: (orderId: string) => void;
    onSearch: () => void;
    onActiveChange: (active: boolean) => void;
    activeTab: number;
}

const columnData = [
    {
        title: "Name",
        render: (tabName: string) => <span>{tabName}</span>,
    },
    {
        title: "Owner",
        render: (owner: string) => <span>{owner}</span>,
    },
    {
        title: "Duration",
        render: (date: string, item: FourFields) =>
            !!item?.success ? (
                <StartandEndDateFormatted start={date.split(",")[0]} end={date.split(",")[1]} />
            ) : (
                <DateFormatted value={date} toUpperCase={true} />
            ),
    },
    {
        title: "Total charged",
    },
];

const ROWS_PER_PAGE = 7;

export const LocationGroupTabs = ({
    groupTabs,
    loading,
    onSearch,
    onActiveChange,
    displayPage,
    locationId,
    totalCount,
    getLocationGroupTabs,
    goToPage,
    showGroupTab,
    selectedGroupTab,
    setDatesFilter,
    removeDatesFilter,
    setOwnerFilter,
    groupTabfilters,
    activeTab,
}: Props) => {
    useEffect(() => {
        if (activeTab === 1) {
            getLocationGroupTabs(locationId, ROWS_PER_PAGE * (displayPage - 1), ROWS_PER_PAGE, groupTabfilters);
        }
        // Otherwise this fires on every groupTabfilters change, but the requirement is to fire this on page load and pagination/search button click.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLocationGroupTabs, locationId, displayPage, activeTab]);

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        setOwnerFilter(e.target.value);
    }
    function onDateSelect(from: string, to: string) {
        if (!from || !to) {
            removeDatesFilter();
            return;
        }
        setDatesFilter(from, to);
    }

    return (
        <>
            {loading && !groupTabs.length ? (
                <Spinner size="large" />
            ) : (
                <div className="location-groupTabs">
                    <div className="location-groupTabs__inputs">
                        <Input
                            className="location-groupTabs__input location-groupTabs__filter"
                            defaultValue={groupTabfilters?.ownerName || undefined}
                            allowClear
                            placeholder="Owner name"
                            onChange={onChange}
                        />
                        <RadioButtons
                            className="location-groupTabs__input radio-buttons location-groupTabs__filter"
                            handleChange={onActiveChange}
                            selectedValue={!!groupTabfilters?.active}
                            items={[
                                { value: true, label: "Last 3 days" },
                                { value: false, label: "Older" },
                            ]}
                        />

                        <div className="location-groupTabs__input location-groupTabs__filter date-picker">
                            {!groupTabfilters?.active && (
                                <DateRange
                                    defaultValues={
                                        groupTabfilters?.dates
                                            ? [groupTabfilters.dates.from, groupTabfilters.dates.to]
                                            : undefined
                                    }
                                    onSelect={onDateSelect}
                                    hidePresets={true}
                                    disabledDates={disabledDates}
                                />
                            )}
                        </div>

                        <Button
                            className="location-groupTabs__filter search-button"
                            search
                            onClick={() => onSearch()}
                            value="Search"
                        />
                    </div>
                    <Tables
                        layoutType={LayoutType.FOUR_COLUMNS}
                        dataSource={groupTabs}
                        selected={selectedGroupTab}
                        tableTitle=""
                        columnData={columnData}
                        startColumn={selectedGroupTab ? 1 : 0}
                        goToPage={goToPage}
                        page={displayPage}
                        selectRow={(row: any) => showGroupTab(row.id)}
                        pageSize={ROWS_PER_PAGE}
                        showPagination={!selectedGroupTab}
                        totalCount={totalCount}
                    />
                </div>
            )}
        </>
    );
};

//To disable date range in the future and the last 3 days, including today.
const disabledDates = (current: moment.Moment) => {
    let customDate = moment().subtract(3, "days").format("YYYY-MM-DD");
    return current && current > moment(customDate, "YYYY-MM-DD");
};
