import React from "react";
import { reducer } from "./reducers";
import { Route } from "react-router";
import { FeatureConfiguration } from "app/featureConfig";
import { LocationPageContainer } from "./containers/LocationPageContainer";

export * from "./types";

export default function (config: FeatureConfiguration) {
    config.reducers.location = reducer;

    config.routes.locationRoutes.push(
        <Route path="/:region/location/:location_id/:page?" exact key="locations" component={LocationPageContainer} />
    );
}
