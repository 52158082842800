import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { TransactionTab, Props } from "../components/TransactionTab";
import { getPartyMember } from "../selectors";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        party: { party },
    } = state;

    if (party.status === "loaded") {
        const partyMember = getPartyMember(state);
        var refunds = party.data.refunds || [];
        var payments = partyMember?.payments.concat(refunds);

        return {
            payments: payments,
            locale: party.data.locale,
            currency: party.data.currency,
            loading: party.status === "loading",
        };
    } else {
        return {
            loading: party.status === "loading",
        };
    }
};
export const TransactionTabContainer = connect(mapStateToProps)(TransactionTab);
