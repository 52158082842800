import React, { useMemo } from "react";
import { Order, PaymentTotal } from "..";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import { Spinner, Divider, PercentageKeyValueRow } from "sharedComponents";
import { Currency, Text } from "sharedComponents";
import "../assets/PaymentTab.scss";
import { NoData } from "./NoData";
import { PartyGroupTab, Refund } from "../types";
import { region } from "../../region";
import { getProcessingFeeRowTitle } from "../utils/currencyHelper";

export interface Props {
    loading: boolean;
    order?: Order;
    locale?: string;
    currency?: string;
    paymentTotal?: PaymentTotal;
    groupTab?: PartyGroupTab;
    locationId?: string;
    refund?: Refund;
}

export const PaymentTab = ({ loading, order, paymentTotal, locale, currency, groupTab, locationId, refund }: Props) => {
    const hasVenueServiceFee = !!paymentTotal?.venueServiceFeePercentage && !!paymentTotal?.venueServiceFeeAmount;
    const membershipDiscount = useMemo(() => {
        if (!order || !order.membershipDiscount) {
            return 0;
        }
        return order.membershipDiscount > 0 ? -order.membershipDiscount : order.membershipDiscount;
    }, [order]);

    return loading ? (
        <Spinner size="large" />
    ) : !order ? (
        <NoData />
    ) : (
        <div className="party-payment">
            {!!order.discount && (
                <KeyValueRow
                    item={{
                        label: <>Venue discount</>,
                        value: <Currency locale={locale!} currency={currency!} value={order.discount || 0} />,
                    }}
                />
            )}
            {!!membershipDiscount && (
                <KeyValueRow
                    mode="emphasis"
                    item={{
                        label: `Member discount: ${order.membershipLevelName}`,
                        value:
                            order.orderTotal === order.subtotal ? (
                                <>
                                    (<Currency locale={locale!} currency={currency!} value={membershipDiscount} />)
                                </>
                            ) : (
                                <Currency locale={locale!} currency={currency!} value={membershipDiscount} />
                            ),
                    }}
                />
            )}

            <KeyValueRow
                disabled={!order.orderTotal}
                item={{
                    label: "Item total",
                    value: <Currency locale={locale!} currency={currency!} value={order.orderTotal} />,
                }}
            />
            <PercentageKeyValueRow
                label="Venue surcharge"
                percentage={order.surcharge?.percentage}
                formatOptions={{ maximumFractionDigits: 2 }}
                value={order.surcharge?.total || 0}
                locale={locale!}
                currency={currency!}
            />
            {!!order.promotions?.length  &&
                order.promotions.map((orderPromotion, index) => (
                    <KeyValueRow
                        key={"promotion_" + index}
                        item={{
                            label: `Promotions: ${orderPromotion.name}`,
                            value: <Currency locale={locale!} currency={currency!} value={orderPromotion.amount} />,
                        }}
                    />
                ))}
            {!!order.trayCharges?.length &&
                order.trayCharges.map((orderTrayCharge, index) => (
                    <KeyValueRow
                        key={"trayCharge_" + index}
                        item={{
                            label: orderTrayCharge.name || 'Venue tray charge',
                            value: <Currency locale={locale!} currency={currency!} value={orderTrayCharge.amount} />,
                        }}
                    />
                ))}
            <TaxItems order={order} locale={locale!} currency={currency!} />
            {!!groupTab?.gratuityFactor && (
                <PercentageKeyValueRow
                    value={paymentTotal?.tips || 0}
                    percentage={groupTab.gratuityFactor}
                    locale={locale!}
                    currency={currency!}
                    label="Venue gratuity"
                />
            )}

            {!!order.split && (
                <KeyValueRow
                    mode="emphasis"
                    item={{
                        label: (
                            <>
                                <Text>Split Total </Text>
                                {!!order.split.memberCount && (
                                    <Text mode="label">&nbsp;(By {order.split.memberCount} users)</Text>
                                )}
                            </>
                        ),
                        value: <Currency locale={locale!} currency={currency!} value={order.split.individualTotal} />,
                    }}
                />
            )}
            <Divider />
            {!!paymentTotal && !!paymentTotal?.totalCharged && (
                <>
                    {!!groupTab && !!locationId ? (
                        <KeyValueRow
                            mode="emphasis"
                            item={{
                                label: (
                                    <Text mode={["link", "bold"]}>
                                        <a
                                            href={`/${region.getActiveRegion().id}/location/${locationId}/1/grouptab/${
                                                groupTab.id
                                            }`}
                                        >
                                            Paid from Group Tab
                                        </a>
                                    </Text>
                                ),
                                value: (
                                    <Currency
                                        locale={locale!}
                                        currency={currency!}
                                        value={(paymentTotal.totalCharged || 0) - (paymentTotal.totalRefunded || 0)}
                                    />
                                ),
                            }}
                        />
                    ) : (
                         <>
                             {hasVenueServiceFee &&
                                 <PercentageKeyValueRow
                                     label="Venue service fee"
                                     percentage={paymentTotal.venueServiceFeePercentage / 100}
                                     formatOptions={{ maximumFractionDigits: 2 }}
                                     value={paymentTotal.venueServiceFeeAmount}
                                     locale={locale!}
                                     currency={currency!}
                                 />
                             }
                            <KeyValueRow
                                disabled={!paymentTotal.tips}
                                item={{
                                    label: "Tips",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={paymentTotal.tips || 0}
                                        />
                                    ),
                                }}
                            />
                            <KeyValueRow
                                disabled={!paymentTotal.credits}
                                item={{
                                    label: "Credits",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={paymentTotal.credits || 0}
                                        />
                                    ),
                                }}
                            />
                            <KeyValueRow
                                disabled={!paymentTotal.processingFee}
                                item={{
                                    label: getProcessingFeeRowTitle(currency),
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={paymentTotal.processingFee || 0}
                                        />
                                    ),
                                }}
                            />
                            {!!paymentTotal.totalRefunded && (
                                <KeyValueRow
                                    item={{
                                        label: "Total paid",
                                        value: (
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={paymentTotal.totalCharged || 0}
                                            />
                                        ),
                                    }}
                                />
                            )}
                            {!!paymentTotal.totalRefunded && (
                                <KeyValueRow
                                    mode="green"
                                    item={{
                                        label: "Total refunded",
                                        value: (
                                            <Text>
                                                {
                                                    <Currency
                                                        locale={locale!}
                                                        currency={currency!}
                                                        value={0 - (paymentTotal.totalRefunded || 0)}
                                                    />
                                                }
                                            </Text>
                                        ),
                                    }}
                                />
                            )}
                            <KeyValueRow
                                mode="emphasis"
                                item={{
                                    label: "Total charged",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={(paymentTotal.totalCharged || 0) - (paymentTotal.totalRefunded || 0)}
                                        />
                                    ),
                                }}
                            />
                        </>
                    )}
                </>
            )}
            {!!paymentTotal?.totalOutstanding && (
                <KeyValueRow
                    mode="emphasis"
                    item={{
                        label: "Total outstanding",
                        value: <Currency locale={locale!} currency={currency!} value={paymentTotal.totalOutstanding} />,
                    }}
                />
            )}
            {!!refund && !paymentTotal?.totalRefunded && refund.status !== "Refunded" && (
                <>
                    <Divider />
                    <KeyValueRow
                        mode={refund.status !== "Failed" ? "mild" : "red"}
                        item={{
                            label: `${refund.status} refund`,
                            value: (
                                <Text>
                                    {
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={0 - (refund.transactionTotal || 0)}
                                        />
                                    }
                                </Text>
                            ),
                        }}
                    />
                </>
            )}
        </div>
    );
};

const TaxItems = ({ order, locale, currency }: { order: Order; locale: string; currency: string }) => (
    <>
        {!!order.taxTotals &&
            order.taxTotals.map((t) =>
                t.exclusive ? (
                    <KeyValueRow
                        key={`${t.taxClass}_${t.taxRate}`}
                        item={{
                            label: (
                                <>
                                    {"Tax"} {t.taxClass}
                                </>
                            ),
                            value: <Text>{<Currency locale={locale!} currency={currency!} value={t.total} />}</Text>,
                        }}
                    />
                ) : (
                    <KeyValueRow
                        key={`${t.taxClass}_${t.taxRate}`}
                        mode="mild"
                        item={{
                            label: (
                                <>
                                    {"Included"} {t.taxClass}{" "}
                                    {
                                        <Text mode="label">
                                            &nbsp;(
                                            {t.taxRate
                                                .toLocaleString("default", {
                                                    style: "percent",
                                                    minimumFractionDigits: 1,
                                                })
                                                .replace(".0", "")}
                                            )
                                        </Text>
                                    }
                                </>
                            ),
                            value: <Text>({<Currency locale={locale!} currency={currency!} value={t.total} />})</Text>,
                        }}
                    />
                )
            )}
    </>
);
