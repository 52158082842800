import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { PaymentTab, Props } from "../components/PaymentTab";
import { getPartyLastRefund, getPartyMember } from "../selectors";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        party: { party },
    } = state;

    if (party.status === "loaded") {
        return {
            order: party.data.order,
            currency: party.data.currency,
            locale: party.data.locale,
            paymentTotal: getPartyMember(state)?.paymentTotals,
            loading: party.status === "loading",
            groupTab: party.data.groupTab,
            locationId: party.data.location.id,
            refund: getPartyLastRefund(state),
        };
    } else {
        return {
            loading: party.status === "loading",
        };
    }
};

export const PaymentTabContainer = connect(mapStateToProps)(PaymentTab);
