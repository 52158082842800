import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { BillSplitTab, Props } from "../components/BillSplitTab";
import { getBillSplit, getPartyMember } from "../selectors";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        party: { party },
    } = state;

    if (party.status === "loaded") {
        const partyMember = getPartyMember(state);
        return {
            billSplits: getBillSplit(state),
            currency: party.data.currency,
            locale: party.data.locale,
            paymentsTotal: party.data.paymentsTotal,
            memberId: partyMember && partyMember.id,
            partyId: match.params["party_id"],
            loaded: party.status === "loaded",
        };
    } else {
        return {
            loaded: false,
        };
    }
};

export const BillSplitTabContainer = withRouter(connect(mapStateToProps)(BillSplitTab));
