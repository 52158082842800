import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { emailInvoice } from "../api";
import { message } from "antd";

export function email(partyId: string, memberId: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.loadingEmail());

        try {
            const result: boolean = await emailInvoice(partyId, memberId);
            if (result) {
                message.success("Email successfully sent");
            } else {
                message.error("Unable to send email");
            }
            dispatch(actionCreators.emailLoaded(result));
        } catch (e) {
            console.log(e);
            message.error("Unable to send email");
            dispatch(actionCreators.emailFailed(e));
        }
    };
}
