import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { select } from "../actions";
import { LocationInfoSection, Props } from "../components/LocationInfoSection";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        location: { info },
    } = state;

    return {
        location: info.status === "loaded" ? info.data : undefined,
        loading: info.status === "loading",
        locationId: match.params["location_id"],
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        getLocation: (locationId: string) => {
            dispatch(select(locationId));
        },
    };
};

export const LocationInfoContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationInfoSection));
