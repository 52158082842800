import { Tooltip } from "antd";
import React from "react";
import { ActiveIcon, AlertIcon, TickIcon } from "sharedComponents/assets/Icons";
import "../assets/RowGroupTabName.scss";
import { MemberGroupTabData } from "../types";

interface Props {
    tabName?: string;
    groupTabData: MemberGroupTabData;
}

const getIcon = (status: string) => {
    switch (status) {
        case "":
        case "active":
            return <ActiveIcon />;
        case "closed":
            return <TickIcon />;
        case "pending":
            return <AlertIcon />;
        default:
            return null;
    }
};

export const RowGroupTabName = ({ tabName, groupTabData }: Props) => {
    const status = groupTabData.status.toLocaleLowerCase();
    const title = groupTabData?.closeReason ?? status;
    return (
        <div className="row-groupTab-name">
            <Tooltip overlayClassName="row-groupTab-name__tooltip" title={title}>
                <span className="row-groupTab-name__icon">{getIcon(status)}</span>
            </Tooltip>
            <Tooltip overlayClassName="row-groupTab-name__tooltip" title={title}>
                <span className="row-groupTab-name__tabName">{tabName}</span>
            </Tooltip>
        </div>
    );
};
