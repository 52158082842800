import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { getParties } from "../actions";
import { MemberParties, Props } from "../components/MemberParties";
import { getMembersParties } from "../selectors";
import { getPartyMember } from "features/party/selectors";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        member: { parties, memberTab },
    } = state;
    const displayPage = match.params.page;
    const partiesData = getMembersParties(state);
    const partyMember = getPartyMember(state);
    return {
        parties: partiesData,
        totalCount: parties.status === "loaded" ? parties.data.totalCount : 0,
        displayPage: displayPage > 0 ? displayPage * 1 : 1,
        loading: parties.status === "loading",
        memberId: match.params["member_id"] || (partyMember && partyMember.id),
        selectedParty: match.params["party_id"],
        activeTab: memberTab,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, match }: RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        getMemberParties: (memberId: string, skip: number, limit: number) =>
            dispatch(getParties(memberId, skip, limit)),
        goToPage: (page: number) => history.push(`${page}`),
        showParty: (partyId: string, memberId: string) =>
            match.params["party_id"] && match.params["location_id"]
                ? history.replace(`/${match.params.region}/member/${memberId}/party/${partyId}`)
                : match.params["party_id"]
                ? history.replace(`${partyId}`)
                : history.push(`${match.params.page}/party/${partyId}`),
    };
};

export const MemberPartiesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberParties));
