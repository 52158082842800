import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { getParty } from "../api";
import { Party } from "../types";

export function select(partyId: string, locationId: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const partyInfo = getState().party.party;
        if (partyInfo.status === "loaded" && partyInfo.data.id === partyId) return;

        dispatch(actionCreators.loading());

        try {
            const result: Party = await getParty(partyId, locationId);
            dispatch(actionCreators.loaded(result));
        } catch (e) {
            dispatch(actionCreators.failed(e.message));
        }
    };
}
