import React from "react";
import "./Button.scss";
import classNames from "classnames";
import { Text } from "sharedComponents";
import { SearchIcon } from "sharedComponents/assets/Icons";

interface Props {
    value?: string;
    children?: React.ReactNode | React.ReactNode[];
    onClick?: () => void;
    className?: string;
    search?: boolean;
    disabled?: boolean;
}

export const Button: React.FC<Props> = ({ className, value, children, onClick, search, disabled }: Props) => {
    return (
        <span
            className={classNames("button-element", className, (disabled || !onClick) && "disabled")}
            onClick={disabled ? undefined : onClick}
        >
            {!!search && (
                <span className="button-element--icon">
                    <SearchIcon />
                </span>
            )}
            {!!value ? <Text value={value} /> : children}
        </span>
    );
};
