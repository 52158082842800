import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { PaymentTab, Props } from "../components/PaymentTab";
import { getTabTipAmount } from "../selectors";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        groupTab: { groupTab },
    } = state;

    if (groupTab.status === "loaded") {
        return {
            currency: groupTab.data.currency,
            locale: groupTab.data.locale,
            paymentTotals: groupTab.data?.paymentTotals,
            loading: groupTab.status === "loading",
            limit: groupTab.data.limit,
            isTabClosed: groupTab.data.status.toLowerCase() === "closed",
            gratuityFactor: groupTab.data.gratuityFactor,
            tabTipAmount: getTabTipAmount(state),
            tabType: groupTab.data.type,
        };
    } else {
        return {
            loading: groupTab.status === "loading",
        };
    }
};

export const PaymentTabContainer = connect(mapStateToProps)(PaymentTab);
