import { opsApi } from "common/api";
import { LocationValue } from "features/location/types";

const query: string = `query ($query: String!, $maxResults: Int!) {
    searchLocations(query: $query, maxResults: $maxResults) {
      id
      displayName
      slug
    }
  }`;

type GraphQLPayload = { searchLocations: LocationValue[] };

export async function searchLocations(searchValue: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { query: searchValue, maxResults: 7 });
    return res.data.searchLocations;
}
