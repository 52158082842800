import { PartyLocale } from "../types/PartyLocale";

const cache: { [key: string]: PartyLocale | undefined } = {};

export function getPartyLocale(locale: string, currency: string): PartyLocale {
    const key = `${locale}|${currency}`;

    if (cache[key] === undefined) {
        cache[key] = new PartyLocale(locale, currency);
    }

    return cache[key]!;
}
