import React from "react";
import { Tabs as AnyTabs } from "antd";
import classNames from "classnames";
import "antd/dist/antd.css";
import "./Tabs.scss";
import { Text } from "../../controls/text";

const { TabPane } = AnyTabs;

interface Props {
    activeIndex?: number;
    className?: string;
    tabPanes: TabPanes[];
    onActiveTab?: (activeIndex: number) => void;
}

export interface TabPanes {
    label: string;
    content: React.ReactNode;
    disabled?: boolean;
}

export const Tabs: React.FC<Props> = ({ activeIndex, tabPanes, className, onActiveTab }: Props) => {
    return (
        <AnyTabs
            defaultActiveKey={`tab_${activeIndex}`}
            className={classNames("tabs", className)}
            onChange={
                !!onActiveTab
                    ? (activeKey: string) => {
                          onActiveTab(parseInt(activeKey.replace("tab_", "")));
                      }
                    : undefined
            }
        >
            {tabPanes.map((tabPane, index) => (
                <TabPane
                    disabled={tabPane.disabled}
                    tab={<Text preset="header" mode="bold" value={tabPane.label} />}
                    key={`tab_${index}`}
                >
                    {tabPane.content}
                </TabPane>
            ))}
        </AnyTabs>
    );
};
