import { opsApi } from "common/api";

type GraphQLPayload = { groupTabJson: string };

const getGroupTabQuery: string = `query ($groupTabId: ID!, $locationId: ID!) {
    groupTabJson(groupTabId: $groupTabId, locationId: $locationId) 
  }`;

export const getRawGroupTab = async (groupTabId: string, locationId: string) => {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(getGroupTabQuery, { groupTabId, locationId });
    return res.data.groupTabJson || JSON.stringify({ message: "Nothing to show" });
};
