import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { rawGroupTab } from "../actions";
import { RawGroupTabSection, Props } from "../components/RawGroupTabSection";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        groupTab: { rawGroupTab, groupTab },
    } = state;
    if (groupTab.status === "loaded") {
        return {
            rawGroupTabJson: rawGroupTab.status === "loaded" ? rawGroupTab.data : undefined,
            groupTabId: groupTab.data.id,
            locationId: groupTab.data.locationId,
            loading: rawGroupTab.status === "loading",
        };
    } else {
        return {
            loading: rawGroupTab.status === "loading",
        };
    }
};

const mapDispatchToProps = (dispatch: AppDispatch, { match }: RouteComponentProps<any>): FunctionProps<Props> => {
    return {
        getRawGroupTab: (groupTabId: string, locationId: string) => dispatch(rawGroupTab(groupTabId, locationId)),
    };
};

export const RawGroupTabContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(RawGroupTabSection));
