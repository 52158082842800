import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { store, history, AppState } from "./app/store";
import { Provider, useSelector } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import * as serviceWorker from "./serviceWorker";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { useEffect } from "react";
import { initAppInsights, reactPlugin } from "./common/appInsights/AppInsights";

const RenderApp = () => {
    const region = useSelector((state: AppState) => state.region);

    useEffect(() => {
        if (region.selectedRegion.status === "loaded") {
            initAppInsights(region.selectedRegion.data);
        }
    }, [region]);

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <AppInsightsErrorBoundary onError={() => <h1>I believe something went wrong</h1>} appInsights={reactPlugin}>
                <App />
            </AppInsightsErrorBoundary>
        </AppInsightsContext.Provider>
    );
};

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <RenderApp />
            </ConnectedRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
