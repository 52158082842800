import { connect } from "react-redux";
import { LoginPage } from "../components/LoginPage";
import { RouteComponentProps } from "react-router";

export interface OwnProps extends RouteComponentProps<any> {
    auth: any;
}

const mapDispatchToProps = (dispatch: any, { auth, match, history }: OwnProps) => ({
    handleLogin: () => {
        const relativeRoot = match.url; // match.url will only be /:location but we can use it to dynamically chop off /manage
        const { location } = history;
        const startUrl = location.pathname.substr(location.pathname.indexOf(relativeRoot)) + location.search;
        auth.login(startUrl);
    },
});

export const LoginPageContainer = connect(null, mapDispatchToProps)(LoginPage);
