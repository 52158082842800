import React from "react";
import { formatDate, formatTime } from "./helper";

interface Props {
    value: number | string;
    toUpperCase?: boolean;
}

export const DateFormatted: React.FC<Props> = ({ value, toUpperCase }: Props) => {
    const date = new Date(value);
    const formattedTime = formatTime(date);
    const formattedDate = formatDate(date);
    const formatted = `${formattedDate} ${formattedTime}`;
    return <>{toUpperCase && toUpperCase ? formatted.toUpperCase() : formatted}</>;
};
