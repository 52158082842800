import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { MemberPartyResult } from "../types";
import { fetchParties } from "../api/partiesApi";

export function getParties(memberId: string, skip: number, limit: number) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.partiesLoading());

        try {
            const result: MemberPartyResult = await fetchParties(memberId, skip, limit);
            dispatch(actionCreators.partiesLoaded(result));
        } catch (e) {
            dispatch(actionCreators.partiesFailed(e.message));
        }
    };
}
