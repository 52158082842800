import React from "react";
import { Dropdown as AntDropdown, Menu, Button } from "antd";
import { DownIcon } from "sharedComponents/assets/Icons";
import classNames from "classnames";
import "./DropDown.scss";

interface Props {
    options: DropDownOption[];
    onSelect: (key: string, option: any) => void;
    defaultSelected?: string;
    disabled?: boolean;
    className?: string;
}

export type DropDownOption = {
    key: string;
    display: string;
};

const menu = ({ items, onSelect }: { items: DropDownOption[]; onSelect: (params: any) => void }) => (
    <Menu onClick={onSelect}>
        {items.map((item, index) => (
            <Menu.Item key={item.key}>{item.display}</Menu.Item>
        ))}
    </Menu>
);

export const DropDown = ({ options, onSelect, defaultSelected, disabled, className }: Props) => {
    const [select, setSelected] = React.useState(defaultSelected || options[0].display);
    return (
        <AntDropdown
            className={classNames("mnu-dropdown", className)}
            disabled={disabled}
            overlay={menu({
                items: options,
                onSelect: ({ key, item }: any) => {
                    const itemVal = options.find((option) => option.key === key);
                    setSelected(itemVal?.display || select);
                    onSelect(key, itemVal?.display);
                },
            })}
            trigger={["click"]}
        >
            <Button onClick={(e) => e.preventDefault()}>
                {select} <DownIcon />
            </Button>
        </AntDropdown>
    );
};
