import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { getClosedGroupTab, getGroupTab } from "../api";
import { GroupTab } from "../types";

export const select = (groupTabId: string, locationId?: string) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const groupTabInfo = getState().groupTab.groupTab;
        if (groupTabInfo.status === "loaded" && groupTabInfo.data.id === groupTabId) return;

        dispatch(actionCreators.loading());

        try {
            const result: GroupTab = !locationId
                ? await getClosedGroupTab(groupTabId)
                : await getGroupTab(groupTabId, locationId);
            dispatch(actionCreators.loaded(result));
        } catch (e) {
            dispatch(actionCreators.failed(e.message));
        }
    };
};
