import React from "react";

export const TickIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.8332 6.46334V7C12.8325 8.25792 12.4251 9.4819 11.6719 10.4894C10.9188 11.4969 9.86009 12.2339 8.65379 12.5906C7.4475 12.9473 6.15824 12.9044 4.97828 12.4685C3.79832 12.0326 2.79089 11.2269 2.10623 10.1716C1.42158 9.11636 1.09638 7.86804 1.17915 6.61285C1.26192 5.35767 1.74821 4.16286 2.5655 3.20663C3.38279 2.2504 4.48729 1.58398 5.71428 1.30675C6.94127 1.02953 8.22499 1.15637 9.37401 1.66834" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M12.8333 2.33331L7 8.17248L5.25 6.42248" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const AlertIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z" stroke="#FA541C" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 4.6665V6.99984" stroke="#FA541C" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 9.3335H7.00583" stroke="#FA541C" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const ActiveIcon = () => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <path d="M10.0003 15.8337C13.222 15.8337 15.8337 13.222 15.8337 10.0003C15.8337 6.77866 13.222 4.16699 10.0003 4.16699C6.77866 4.16699 4.16699 6.77866 4.16699 10.0003C4.16699 13.222 6.77866 15.8337 10.0003 15.8337Z" fill="#52C41A" />
        </g>
        <defs>
            <filter id="filter0_d" x="-1" y="-1" width="22" height="22" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.584314 0 0 0 0 0.870588 0 0 0 0 0.392157 0 0 0 1 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
)

export const CrossIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.75 5.25L5.25 8.75" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.25 5.25L8.75 8.75" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const IdleIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7 3.5V7L9.33333 8.16667" stroke="#BFBFBF" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const FailedIcon = () => (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.99984 12.8334C10.2215 12.8334 12.8332 10.2217 12.8332 7.00002C12.8332 3.77836 10.2215 1.16669 6.99984 1.16669C3.77818 1.16669 1.1665 3.77836 1.1665 7.00002C1.1665 10.2217 3.77818 12.8334 6.99984 12.8334Z" stroke="#F5222D" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8.75 5.25L5.25 8.75" stroke="#F5222D" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M5.25 5.25L8.75 8.75" stroke="#F5222D" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const ExternalLinkIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.5 9.75V14.25C13.5 14.6478 13.342 15.0294 13.0607 15.3107C12.7794 15.592 12.3978 15.75 12 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V6C2.25 5.60218 2.40804 5.22064 2.68934 4.93934C2.97064 4.65804 3.35218 4.5 3.75 4.5H8.25" stroke="#597EF7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M11.25 2.25H15.75V6.75" stroke="#597EF7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M7.5 10.5L15.75 2.25" stroke="#597EF7" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const SearchIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.25 12.75C10.7353 12.75 12.75 10.7353 12.75 8.25C12.75 5.76472 10.7353 3.75 8.25 3.75C5.76472 3.75 3.75 5.76472 3.75 8.25C3.75 10.7353 5.76472 12.75 8.25 12.75Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M14.25 14.25L12 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const DownIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.8127 4H12.6408C12.5611 4 12.4861 4.03906 12.4393 4.10313L8.0002 10.2219L3.56114 4.10313C3.51426 4.03906 3.43926 4 3.35957 4H2.1877C2.08614 4 2.02676 4.11563 2.08614 4.19844L7.59551 11.7937C7.79551 12.0687 8.20489 12.0687 8.40332 11.7937L13.9127 4.19844C13.9736 4.11563 13.9143 4 13.8127 4Z" fill="#BFBFBF" />
    </svg>
)

export const StartCheckedIcon = () => (
    <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M29.9759 0.834388C31.074 -0.277967 32.926 -0.277967 34.0241 0.834388L39.3595 6.23879C40.0101 6.89783 40.9674 7.19491 41.8993 7.02701L49.5415 5.65013C51.1145 5.36674 52.6128 6.40646 52.8167 7.9229L53.8072 15.2905C53.928 16.189 54.5197 16.9667 55.3769 17.3541L62.4069 20.5307C63.8539 21.1845 64.4262 22.8668 63.6579 24.2081L59.9253 30.7248C59.4702 31.5194 59.4702 32.4808 59.9253 33.2755L63.6579 39.7922C64.4262 41.1334 63.8539 42.8158 62.4069 43.4696L55.3769 46.6461C54.5197 47.0335 53.928 47.8113 53.8072 48.7097L52.8167 56.0773C52.6128 57.5938 51.1145 58.6335 49.5415 58.3501L41.8993 56.9732C40.9674 56.8053 40.0101 57.1024 39.3595 57.7614L34.0241 63.1659C32.926 64.2782 31.074 64.2782 29.9759 63.1659L24.6405 57.7614C23.9899 57.1024 23.0326 56.8053 22.1007 56.9732L14.4585 58.3501C12.8855 58.6335 11.3872 57.5938 11.1833 56.0773L10.1928 48.7097C10.072 47.8113 9.48033 47.0335 8.62307 46.6461L1.59306 43.4696C0.146118 42.8158 -0.42617 41.1334 0.342081 39.7922L4.07465 33.2755C4.52981 32.4808 4.52981 31.5194 4.07465 30.7248L0.342081 24.2081C-0.42617 22.8668 0.146118 21.1845 1.59306 20.5307L8.62307 17.3541C9.48033 16.9667 10.072 16.189 10.1928 15.2905L11.1833 7.9229C11.3872 6.40646 12.8855 5.36674 14.4585 5.65013L22.1007 7.02701C23.0326 7.19491 23.9899 6.89783 24.6405 6.23879L29.9759 0.834388Z" fill="#EF465C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M45.403 23.5748C46.1902 24.3497 46.2002 25.616 45.4253 26.4032L29.6753 42.4032C28.9045 43.1862 27.6465 43.2008 26.8577 42.4359L18.6077 34.4359C17.8147 33.667 17.7953 32.4008 18.5642 31.6078C19.3331 30.8149 20.5993 30.7954 21.3923 31.5643L28.2172 38.1824L42.5747 23.5971C43.3496 22.8099 44.6159 22.7999 45.403 23.5748Z" fill="white" />
    </svg>
)

export const PencilIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.5999 4.69248L14.8352 4.45723C15.7444 3.54773 15.5102 2.30848 14.5999 1.39898C13.6897 0.489485 12.4499 0.253985 11.5414 1.16398L11.3062 1.39923" stroke="#BFBFBF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M13.1597 6.13368L14.6012 4.69243L11.3072 1.39893L9.86621 2.83993" stroke="#BFBFBF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M1.60214 11.1041L4.89539 14.3976M4.89539 14.3976L13.1596 6.13384L9.86614 2.84009L1.60214 11.1041L0.587891 15.4123L4.89539 14.3976Z" stroke="#BFBFBF" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
)

export const SaveIcon = () => (
    <svg viewBox="64 64 896 896" width="22" height="22" fill="currentColor" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
        <path d="M893.3 293.3L730.7 130.7c-7.5-7.5-16.7-13-26.7-16V112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V338.5c0-17-6.7-33.2-18.7-45.2zM384 184h256v104H384V184zm456 656H184V184h136v136c0 17.7 14.3 32 32 32h320c17.7 0 32-14.3 32-32V205.8l136 136V840zM512 442c-79.5 0-144 64.5-144 144s64.5 144 144 144 144-64.5 144-144-64.5-144-144-144zm0 224c-44.2 0-80-35.8-80-80s35.8-80 80-80 80 35.8 80 80-35.8 80-80 80z"></path>
    </svg>
)