import React from "react";
import { DropDown } from "sharedComponents";
import { useSelector } from "react-redux";
import { getAvailableRegionsOptions, getSelectedRegion, isSelectedAvailable } from "../selectors";
import { Text } from "sharedComponents";
import "./RegionSelector.scss";

export const RegionSelector = () => {
    const selectedRegion = useSelector(getSelectedRegion);
    const availableRegions = useSelector(getAvailableRegionsOptions);
    const isAvailable = useSelector(isSelectedAvailable);

    if (availableRegions.length < 2) return null;

    return (
        <div className="region-selection">
            <DropDown
                className="region-selection__options"
                options={availableRegions}
                onSelect={(key: string) => window.location.replace(`/${key}`)}
                defaultSelected={selectedRegion ? selectedRegion.displayName : "Select region..."}
            />
            {isAvailable === false && <Text className="region-selection__denied">Access denied to region</Text>}
        </div>
    );
};
