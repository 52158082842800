import { ColumnSource, Columns, LayoutType } from "./types";
import { ColumnType } from "antd/lib/table";

export const tableColumns = (type: LayoutType, columnData: ColumnSource[], startIndex?: number) => {
    switch (type) {
        case LayoutType.FOUR_COLUMNS:
            return fourColumns(columnData, startIndex || 0);
        case LayoutType.THREE_COLUMNS:
            return threeColumns(columnData, startIndex || 0);
        case LayoutType.FOUR_COLUMNS_WITH_BORDER:
            return fourColumnsWithBorder(columnData);
    }
};

const threeColumns = (columnData: ColumnSource[], startIndex: number): ColumnType<Columns>[] => {
    return [
        {
            title: columnData[startIndex].title,
            dataIndex: `field${++startIndex}`,
            key: "threeColumnsField1",
            width: "38%",
        },
        {
            title: columnData[startIndex].title,
            dataIndex: `field${++startIndex}`,
            key: "threeColumnsField2",
            width: "38%",
            render: columnData[startIndex - 1]?.render,
        },
        {
            className: "last-column",
            title: columnData[startIndex].title,
            dataIndex: `field${++startIndex}`,
            key: "threeColumnsField3",
            width: "24%",
            render: columnData[startIndex - 1]?.render,
        },
    ];
};

const fourColumns = (columnData: ColumnSource[], startIndex: number): ColumnType<Columns>[] => {
    return [
        {
            title: columnData[startIndex].title,
            dataIndex: `field${++startIndex}`,
            key: "fourColumnsField1",
            width: "28%",
            render: columnData[startIndex - 1].render,
        },
        {
            title: columnData[startIndex].title,
            dataIndex: `field${++startIndex}`,
            key: "fourColumnsField2",
            width: "28%",
            render: columnData[startIndex - 1]?.render,
        },
        {
            title: columnData[startIndex].title,
            dataIndex: `field${++startIndex}`,
            key: "fourColumnsField3",
            width: "28%",
            render: columnData[startIndex - 1]?.render,
        },
        {
            className: "last-column",
            title: columnData[startIndex].title,
            dataIndex: `field${++startIndex}`,
            key: "fourColumnsField4",
            width: "16%",
            render: columnData[startIndex - 1]?.render,
        },
    ];
};

const fourColumnsWithBorder = (columnData: ColumnSource[]): ColumnType<Columns>[] => {
    return [
        {
            dataIndex: "field1",
            key: "fourColumnsWithBorderField1",
            width: "60%",
            render: columnData[0].render,
        },
        {
            dataIndex: "field2",
            key: "fourColumnsWithBorderField2",
            width: "34%",
            render: columnData[1] && columnData[1].render ? columnData[1].render : undefined,
        },
        {
            dataIndex: "field3",
            key: "fourColumnsWithBorderField3",
            width: "1%",
        },
        {
            className: "last-column",
            dataIndex: "field4",
            key: "fourColumnsWithBorderField4",
            width: "5%",
        },
    ];
};
