import React, { useMemo } from "react";
import { Content, Section } from "sharedComponents";
import { MemberInfoContainer } from "../../member/containers/MemberInfoContainer";
import { BreadcrumbContainer } from "features/breadcrumb/containers/BreadcrumbContainer";
import { DetailsContainer } from "../containers/DetailsContainer";
import { Tabs } from "sharedComponents/dataDisplay/tabs";
import { PaymentTabContainer } from "../containers/PaymentTabContainer";
import { TransactionTabContainer } from "../containers/TransactionTabContainer";
import { OrderItemsContainer } from "../containers/OrderItemsContainer";
import { RawGroupTabContainer } from "../containers/RawGroupTabContainer";

export interface Props {
    noTransactions: boolean;
}

export const GroupTabsPage = ({ noTransactions }: Props) => {
    const tabs = useMemo(() => {
        return [
            {
                content: <PaymentTabContainer />,
                label: "Payment",
            },
            {
                content: <TransactionTabContainer />,
                label: "Transaction",
                disabled: noTransactions,
            },
        ];
    }, [noTransactions]);

    return (
        <>
            <Content>
                <MemberInfoContainer />
                <>
                    <DetailsContainer />
                    <Section>
                        <Tabs tabPanes={tabs} />
                    </Section>
                    <OrderItemsContainer />
                    <RawGroupTabContainer />
                </>
            </Content>
            <BreadcrumbContainer />
        </>
    );
};
