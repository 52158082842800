import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { getRawParty } from "../api";

export function rawParty(partyId: string, locationId: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const partyInfo = getState().party;
        if (partyInfo.status === "loaded" && partyInfo.data.id === partyId) return;

        dispatch(actionCreators.loadingJson());

        try {
            const result: string = await getRawParty(partyId, locationId);
            dispatch(actionCreators.jsonLoaded(result));
        } catch (e) {
            dispatch(actionCreators.jsonFailed(e.message));
        }
    };
}
