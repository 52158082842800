import React from "react";
import { Table } from "antd";
import { TableProps } from "antd/lib/table";
import "antd/dist/antd.css";
import classNames from "classnames";
import "./Tables.scss";
import { ColumnSource, Columns, LayoutType } from "./types";
import { tableColumns } from "./Columns";
import { Props as ItemProps } from "../item";
import { Text } from "sharedComponents";

interface Props extends TableProps<Columns> {
    layoutType: LayoutType;
    dataSource: any[];
    tableTitle?: string;
    columnData: ColumnSource[];
    totalCount?: number;
    page?: number;
    startColumn?: number;
    showPagination?: boolean;
    selected?: string;
    pageSize?: number;
    goToPage?: (pageNumber: number) => void;
    tableFooter?: ItemProps;
    selectRow?: (rowData: any) => void;
}

export const Tables: React.FC<Props> = (props: Props) => {
    const { className, tableTitle, layoutType, tableFooter, columnData, dataSource } = props;
    function onChange(pageNumber: number) {
        props.goToPage && props.goToPage(pageNumber);
    }
    return (
        <div className="tables">
            {tableTitle && (
                <div className="tables__title">
                    <Text preset="header" mode="label">
                        {tableTitle}
                    </Text>
                </div>
            )}
            <Table
                {...props}
                dataSource={dataSource}
                className={classNames("tables__content", className, {
                    "cell-border": layoutType === LayoutType.FOUR_COLUMNS_WITH_BORDER,
                })}
                columns={tableColumns(layoutType, columnData, props.startColumn)}
                onRow={(record) => ({
                    onClick: () => props.selectRow && props.selectRow(record),
                })}
                rowKey={(record, index) => `row_${record.id}_${index}`}
                rowClassName={(record, index) => (record.id === props.selected ? "highlight" : "")}
                pagination={
                    props.showPagination
                        ? {
                              onChange,
                              hideOnSinglePage: true,
                              showQuickJumper: true,
                              showSizeChanger: false,
                              current: props.page || 1,
                              position: ["bottomCenter"],
                              pageSize: props.pageSize || props.dataSource?.length || 10,
                              total: props.totalCount,
                          }
                        : false
                }
                footer={tableFooter ? () => renderFooter(tableFooter) : undefined}
            />
        </div>
    );
};

const renderFooter = (footers: ItemProps) => {
    const { label, value } = footers;
    return (
        <div className="tables__footer">
            <div className="tables__footer__label">{label}</div>
            <div className="tables__footer__value">{value}</div>
        </div>
    );
};
