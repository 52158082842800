import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GroupTabSearchResult, OrderSearchResult, State } from "../types";
import { MemberValue } from "features/member/types";
import { LocationValue } from "features/location";

const SEARCH_TAB_KEY = "SEARCH_TYPE_KEY";

const initialState: State = {
    values: { status: "unloaded" },
    searchKey: "memberId",
    searchOrderKey: "transactionId",
    searchTab: JSON.parse(window.localStorage.getItem(SEARCH_TAB_KEY) || "0"),
};

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        loading: (state: State) => {
            state.values.status = "loading";
        },
        loaded: (
            state: State,
            action: PayloadAction<MemberValue[] | LocationValue[] | OrderSearchResult[] | GroupTabSearchResult[]>
        ) => {
            state.values.status = "loaded";
            if (state.values.status === "loaded") {
                state.values.data = action.payload;
            }
        },
        failed: (state: State, action: PayloadAction<string>) => {
            state.values.status = "failed";
            if (state.values.status === "failed") {
                state.values.error = action.payload;
            }
        },
        setSearchKey: (state: State, action: PayloadAction<string>) => {
            state.searchKey = action.payload;
            state.values.status = "unloaded";
        },
        setSearchOrderKey: (state: State, action: PayloadAction<string>) => {
            state.searchOrderKey = action.payload;
            state.values.status = "unloaded";
        },
        setSearchTab: (state: State, action: PayloadAction<number>) => {
            window.localStorage.setItem(SEARCH_TAB_KEY, JSON.stringify(action.payload));
            state.searchTab = action.payload;
            state.values.status = "unloaded";
        },
    },
});

export const actionCreators = searchSlice.actions;
export const reducer = searchSlice.reducer;
