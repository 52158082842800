import { opsApi } from "common/api";

type GraphQLPayload = { partyJson: string };

const getPartyQuery: string = `query ($partyId: ID!, $locationId: ID!) {
    partyJson(partyId: $partyId, locationId: $locationId) 
  }`;

export async function getRawParty(partyId: string, locationId: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(getPartyQuery, { partyId, locationId });
    return res.data.partyJson || JSON.stringify({ message: "Nothing to show" });
}
