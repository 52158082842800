import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { RouteComponentProps, withRouter } from "react-router";
import { OrderItemsSection, Props } from "../components/OrderItemsSection";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        groupTab: { groupTab },
    } = state;
    if (groupTab.status === "loaded") {
        return {
            items: groupTab.data.orders,
            locationId: groupTab.data.locationId,
            locale: groupTab.data.locale,
            currency: groupTab.data.currency,
            total: groupTab.data.paymentTotals?.total,
            IsTabClosed: groupTab.data.status.toLowerCase() === "closed",
        };
    } else {
        return {
            loading: groupTab.status === "loading",
        };
    }
};

export const OrderItemsContainer = withRouter(connect(mapStateToProps)(OrderItemsSection));
