import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { Props } from "../components/SearchGroupTabs";
import { AppDispatch, AppState } from "app/store";
import { searchGroupTab, searchVenue } from "../actions";
import { actionCreators } from "../reducers";
import { getLocationsOptions, getGroupTabOptions } from "../selectors";
import { withRouter, RouteComponentProps } from "react-router";
import { region } from "features/region";
import { SearchGroupTabs } from "../components/SearchGroupTabs";

interface OwnProps {
    onSelectComplete: () => void;
}

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        search: { values },
    } = state;
    const locationOptions = getLocationsOptions(state);
    const groupTabOptions = getGroupTabOptions(state);
    return {
        locationOptions,
        groupTabOptions,
        loading: values.status === "loading",
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, onSelectComplete }: OwnProps & RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        searchLocation: (value: string, force?: boolean) => {
            if (value.length < 2) {
                dispatch(actionCreators.loaded([]));
                return;
            }
            // Search should happen only on demand and not on change
            force && dispatch(searchVenue(value));
        },
        searchGroupTabs: (location: string, value: string, force?: boolean) => {
            if (value.length < 2) {
                dispatch(actionCreators.loaded([]));
                return;
            }
            // Search should happen only on demand and not on change
            force && dispatch(searchGroupTab(location, value));
        },
        select: (location: string, grouptab: string) => {
            onSelectComplete();
            history.push(`/${region.getActiveRegion().id}/location/${location}/grouptab/${grouptab}`);
        },
        resetOrders: () => dispatch(actionCreators.loaded([])),
    };
};

export const SearchGroupTabsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchGroupTabs));
