import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { State } from "../types";

export const PARTIES_FILTERS_KEY = "PARTIES_FILTERS_KEY";
export const GROUPTABS_FILTERS_KEY = "GROUPTABS_FILTERS_KEY";

const initialState: State = {
    har: { status: "unloaded" }    
};

export const instrumentationSlice = createSlice({
    name: "instrumentation",
    initialState,
    reducers: {
        harLoading(state: State) {
            state.har.status = "loading";
        },
        harFailed(state: State) {
            state.har.status = "failed";
        },
        harLoaded(state: State, action: PayloadAction<{}>) {
            state.har = {
              status: "loaded",
              data: action.payload
            };
        }
    },
});

export const actionCreators = instrumentationSlice.actions;
export const reducer = instrumentationSlice.reducer;
