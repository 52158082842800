import { opsApi } from "common/api";
import { GroupTab } from "../types";

type GraphQLPayload = { groupTab: GroupTab };

export const getGroupTab = async (groupTabId: string, locationId: string) => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(getGroupTabQuery, { groupTabId, locationId });
        return res.data.groupTab || {};
    } catch (e) {
        throw e;
    }
};

export const getGroupTabFields: string = `
        id
        tabName
        type
        status
        limit
        owner
        locale
        ownerName
        dateOpened
        dateClosed
        locationId
        locationName
        currency
        gratuityFactor
        orders {
            id
            partyId
            packageName
            amount
            dateCreated
            status
            member{
                memberId
                displayName
            }
        }
        members {
            firstName
            lastName
            memberId
        }
        paymentTotals {
            taxSummary{
                total
                taxClass
                taxRate
                exclusive
            }
            surcharges
            processingFee
            totalCharged
            totalRefunded
            total
            totalGratuity
        }
        credits {
            id
            status
            amount
            processingFee
            transactionId
            transactionLink
            dateCreated
            currency
            transactionTotal
            refundId
            refundLink
            refundedAmount
            paymentMethod
            tipOnly
        }
`;
const getGroupTabQuery = `query ($groupTabId: ID!, $locationId: ID!) {
    groupTab(groupTabId: $groupTabId, locationId: $locationId) {
        ${getGroupTabFields}
    }
}`;
