import React from "react";
import "./Content.scss";

interface Props {
    /**
     * children is expected to be an array of 2 reactNodes
     * eachone could be single or array of reactNode
     */
    children: [React.ReactNode | React.ReactNode[], React.ReactNode | React.ReactNode[]];
}

export const Content = ({ children }: Props) => (
    <div className="content">
        <div className="content--left-panel">{children[0]}</div>
        <div className="content--right-panel">{children[1]}</div>
    </div>
);
