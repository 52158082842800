import { Middleware } from "@reduxjs/toolkit";
import initHome from "features/login/featureConfiguration";
import initSearch from "features/search";
import initMember from "features/member";
import initLocation from "features/location";
import initParty from "features/party";
import initRegion from "features/region";
import initGroupTabs from "features/groupTabs";
import initInstrumentation from "features/instrumentation";

export interface RoutesConfiguration {
    locationRoutes: React.ReactNode[];
}
export interface FeatureConfiguration {
    middleware: Middleware[];
    reducers: any;
    routes: RoutesConfiguration;

    addRoutes(target: React.ReactNode[], routes: React.ReactNode[]): void;
}

type InitFeatureFn = (config: FeatureConfiguration) => void;

const initFeatureFunctions: InitFeatureFn[] = [
    initSearch,
    initMember,
    initLocation,
    initParty,
    initHome,
    initRegion,
    initGroupTabs,
    initInstrumentation,
];
const featureConfig: FeatureConfiguration = {
    middleware: [],
    reducers: {},
    routes: {
        locationRoutes: [],
    },
    addRoutes: (target: React.ReactNode[], routes: React.ReactNode[]) => {
        routes.forEach((r) => {
            target.push(r);
        });
    },
};

for (const initFeature of initFeatureFunctions) {
    initFeature(featureConfig);
}

export default featureConfig;
