import React from "react";
import { Text } from "sharedComponents";

interface Props {
    title?: string;
    overrideText?: string;
}

export const NoData = ({ title, overrideText }: Props) => {
    if (!title) {
        return <Text mode="label" value={overrideText || "Nothing to show"} />;
    }
    return (
        <div style={{ height: "200px" }}>
            <Text preset="header" mode="label" value={title} />
            <div style={{ height: "80%", display: "flex", alignItems: "center" }}>
                <Text mode="label" value={overrideText || "Nothing to show"} />
            </div>
        </div>
    );
};
