import { GraphQLResult, graphQLQuery as commonGraphQLQuery } from "../../API";
import { auth } from "common/auth";
import { region } from "features/region";
import { config } from "common/config";

const baseUrl = config.REACT_APP_OPS_API!;

export const fetch = (url: string, init?: RequestInit) => window.fetch(`${baseUrl}${url}`, authorize(init));

export async function graphQLQuery<T = any>(query: string, variables?: {}): Promise<GraphQLResult<T>> {
    const result = await commonGraphQLQuery(`/graphql`, query, variables);

    return result as GraphQLResult<T>;
}
export function authorize(init?: RequestInit): RequestInit | undefined {
    const accessToken = auth.getAccessToken();

    if (accessToken === null) {
        return init;
    }

    return {
        ...init,
        headers: {
            "x-meandu-region": region.getActiveRegion().id,
            ...(init ? init.headers : undefined),
            Authorization: `Bearer ${accessToken}`,
        },
    };
}
