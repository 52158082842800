import { opsApi } from "common/api";
import { MemberValue } from "features/member/types";

const query: string = `query ($searchKey: String!, $searchTerm: String!,$maxResults: Int!) {
    searchMembers(searchKey: $searchKey, searchTerm: $searchTerm, maxResults: $maxResults) {
      id
      firstName
      lastName
      phoneNumber
      email,
      memberType
    }
  }`;

type GraphQLPayload = { searchMembers: MemberValue[] };

export async function searchMembers(searchKey: string, searchTerm: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(query, { searchKey, searchTerm, maxResults: 7 });
    return res.data.searchMembers;
}
