import { PendingQuery } from "./PendingQuery";

let pendingQuery: PendingQuery | null = null;

type QueryInvoker = (query: string, variables?: {}) => Promise<{}>;

export function batchGraphQLQuery(query: string, variables: {} | undefined, invoker: QueryInvoker) {
    if (pendingQuery) {
        if (pendingQuery.tryMerge(query, variables)) {
            return pendingQuery.promise;
        }

        return invoker(query, variables);
    }

    pendingQuery = new PendingQuery(query, variables || {}, (q, v) => {
        pendingQuery = null;
        return invoker(q, v);
    });

    return pendingQuery.promise;
}
