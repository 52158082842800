import React, { useCallback, useState } from "react";
import { ListItemProps } from "antd/lib/list/Item";
import { Input, Item, Text } from "sharedComponents";
import { CrossIcon, PencilIcon, SaveIcon } from "sharedComponents/assets/Icons";
import "./EditableItem.scss";

export interface Props extends ListItemProps {
    label: string | JSX.Element;
    defaultValue?: string;
    disabled?: boolean;
    onUpdate: (value: string) => void;
    error?: string;
}

export const EditableItem = ({ label, defaultValue = "", disabled, onUpdate, error }: Props) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState<string>(defaultValue);

    const onUpdateEmail = useCallback(() => {
        if (value === defaultValue) {
            setEditing(false);
            return;
        }
        onUpdate(value);
    }, [onUpdate, value, defaultValue]);

    const onCancelUpdate = useCallback(() => {
        setValue(defaultValue);
        setEditing(false);
    }, [defaultValue]);

    return (
        <>
            {editing && (
                <>
                    <div className="item-label">
                        <Text mode="label">{label}</Text>
                    </div>
                    <div className="item-container editing">
                        <Input defaultValue={defaultValue} error={error} onChange={(e) => setValue(e.target.value)} />
                        <span onClick={onUpdateEmail}>
                            <SaveIcon />
                        </span>
                        <span onClick={onCancelUpdate}>
                            <CrossIcon />
                        </span>
                    </div>
                </>
            )}
            {!editing && (
                <div className="item-container">
                    <Item label={label} value={defaultValue} />
                    {!disabled && (
                        <span onClick={() => setEditing(true)}>
                            <PencilIcon />
                        </span>
                    )}
                </div>
            )}
        </>
    );
};
