import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, GroupTab } from "../types";
import { select } from "features/location/actions";

const initialState: State = {
    groupTab: { status: "unloaded" },
    rawGroupTab: { status: "unloaded" },
    email: { status: "unloaded" },
};

export const groupTabSlice = createSlice({
    name: "groupTabs",
    initialState,
    reducers: {
        loading: (state: State) => {
            state.groupTab.status = "loading";
        },
        loaded: (state: State, action: PayloadAction<GroupTab>) => {
            state.groupTab.status = "loaded";
            if (state.groupTab.status === "loaded") {
                state.groupTab.data = action.payload;
                state.rawGroupTab.status = "unloaded";
            }
        },
        failed: (state: State, action: PayloadAction<Error>) => {
            state.groupTab.status = "failed";
            if (state.groupTab.status === "failed") {
                state.groupTab.error = action.payload;
                state.rawGroupTab.status = "unloaded";
            }
        },
        loadingEmail: (state: State) => {
            state.email.status = "loading";
        },
        emailLoaded: (state: State, action: PayloadAction<boolean>) => {
            state.email.status = "loaded";
            if (state.email.status === "loaded") {
                state.email.data = action.payload;
            }
        },
        emailFailed: (state: State, action: PayloadAction<Error>) => {
            state.email.status = "failed";
            if (state.email.status === "failed") {
                state.email.error = action.payload;
            }
        },
        loadingJson: (state: State) => {
            state.rawGroupTab.status = "loading";
        },
        jsonLoaded: (state: State, action: PayloadAction<string>) => {
            state.rawGroupTab.status = "loaded";
            if (state.rawGroupTab.status === "loaded") {
                state.rawGroupTab.data = action.payload;
            }
        },
        jsonFailed: (state: State, action: PayloadAction<Error>) => {
            state.rawGroupTab.status = "failed";
            if (state.rawGroupTab.status === "failed") {
                state.rawGroupTab.error = action.payload;
            }
        },
    },

    extraReducers: (builder) => {
        builder.addCase(select.fulfilled, (state) => {
            state.groupTab.status = "unloaded";
        });
    },
});

export const actionCreators = groupTabSlice.actions;
export const reducer = groupTabSlice.reducer;
