import React, { ReactNode } from "react";
import "antd/dist/antd.css";
import "./Nav.scss";
import { Text } from "../../controls/text";
import { RadioButtons } from "sharedComponents";
import { region } from "features/region";

interface Props {
    icon?: React.ReactNode;
    searchField?: React.ReactNode;
    radioLabel1?: string;
    radioLabel2?: string;
    radioValue?: RadioType;
    radioOnChange?: (value: RadioType) => void;
    optionLabel1?: string;
    optionLabel2?: string;
    optionFunc1?: () => void;
    optionFunc2?: () => void;
    mainAction?: ReactNode;
}

export enum RadioType {
    OPTION1 = 0,
    OPTION2 = 1,
}

export const Nav: React.FC<Props> = ({
    icon,
    searchField,
    radioLabel1,
    radioLabel2,
    optionLabel1,
    optionLabel2,
    optionFunc1,
    optionFunc2,
    radioValue,
    radioOnChange,
    mainAction,
}: Props) => {
    return (
        <div className="nav">
            <span className="nav__section">
                <a className="nav__icon" href={`/${region.getActiveRegion().id}`} target="_self">
                    {icon}
                </a>
                {!!searchField && <div className="nav__search-field">{searchField}</div>}
                {radioValue !== undefined && (!!radioLabel1 || !!radioLabel2) && (
                    <RadioButtons
                        className="nav__radios"
                        handleChange={radioOnChange}
                        selectedValue={radioValue}
                        items={[
                            { value: RadioType.OPTION1, label: radioLabel1 },
                            { value: RadioType.OPTION2, label: radioLabel2 },
                        ]}
                    />
                )}
            </span>
            <span className="nav__section">
                {!!mainAction && <span className="nav__main-action">{mainAction}</span>}
                {optionFunc1 && optionLabel1 && (
                    <span className="nav__option" onClick={optionFunc1}>
                        <Text preset="info-title">{optionLabel1}</Text>
                    </span>
                )}
                {optionFunc2 && optionLabel2 && (
                    <span className="nav__option" onClick={optionFunc2}>
                        <Text preset="info-title">{optionLabel2}</Text>
                    </span>
                )}
            </span>
        </div>
    );
};
