import { Loaded } from "common/loader";

export type State = {
    groupTab: Loaded<GroupTab>;
    rawGroupTab: Loaded<string>;
    email: Loaded<boolean>;
};

export type GroupTab = {
    id: string;
    tabName: string;
    type: TabType;
    status: string;
    limit: number;
    locationId: string;
    locationName: string;
    owner: string;
    ownerName: string;
    currency: string;
    locale: string;
    packageName?: string;
    dateOpened?: string;
    dateClosed?: string;
    orders: OrderItem[];
    paymentsTotal?: number;
    credits: Payment[];
    paymentTotals: PaymentTotal;
    members: GroupTabMember[];
};

export type OrderItem = {
    id?: string;
    partyId?: string;
    member: OrderMember;
    amount?: number;
    dateCreated: string;
    status: string;
    packageName?: string;
    menuType?: string;
};
export type Surcharge = {
    percentage?: number;
    total: number;
};

export interface Payment extends IPayment {}
export interface IPayment {
    id: string;
    amount?: number;
    transactionTotal: number;
    transactionId?: string;
    transactionLink?: string;
    error?: Error;
    paymentMethod?: string;
    dateCreated: string;
    status: string;
    refundId?: string;
    refundLink?: string;
    refundedAmount?: number;
    processingFee?: number;
    tipOnly: boolean;
}
export type PaymentTotal = {
    processingFee?: number;
    totalCharged?: number;
    totalRefunded?: number;
    total: number;
    taxSummary?: TaxTotal[];
    surcharges: number;
    totalGratuity?: number;
};

export type GroupTabMember = {
    memberId: string;
    firstName: string;
    lastName: string;
    displayName: string;
};
export type Error = {
    code: string;
    message: string;
};

export type OrderMember = {
    memberId: string;
    displayName: string;
};

export type TaxTotal = {
    taxClass: string;
    taxRate: number;
    total: number;
    exclusive: boolean;
};

export enum TabType {
    PayOnCreate = "PAYONCREATE",
    PayOnOrder = "PAYONORDER",
}

export enum PaymentStatus {
    PENDING = "PENDING",
    COMPLETE = "COMPLETE",
    FAILED = "FAILED",
}
