import { opsApi } from "common/api";
import { LocationValue } from "../types";

type GraphQLPayload = { location: LocationValue };

const getLocationQuery: string = `query ($locationId: ID!) {
    location(locationId: $locationId) {
        id
        slug
        displayName
        group {
            id
            displayName
        }
        postype
        dateCreated
        companyDetails {
            abn
            registeredName
        }
        flags
        posMetadata {
            posDisplayName
            venuePosDisplayName
            operations {
                name
                supported
                fields {
                    name
                    title
                    subtitle
                    validation
                    input
                    placeholder
                    visible
                    lookup
                }
            }
        }
    }
  }`;

export async function getLocation(locationId: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(getLocationQuery, { locationId });
    return res.data.location;
}
