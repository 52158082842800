import { opsApi } from "common/api";
import { OrderSearchResult } from "../types";

type GraphQLPayload = { searchParties: { totalCount: number; data: OrderSearchResult[] } };

const getLocationQuery: string = `query ($locationId: ID!, $searchKey: String!, $searchTerm: String!, $skip: Int!, $limit: Int!) {
    searchParties(locationId: $locationId, searchKey: $searchKey, searchTerm: $searchTerm, skip: $skip, limit: $limit) {
        totalCount
        data {
            id
            locationId
            tableNumber
            amount
            locale
            currency
            date
            transactionId
            paymentMethod
            subTotal
        }
    }
  }`;

export async function searchParties(locationId: string, searchKey: string, searchTerm: string) {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(getLocationQuery, {
            locationId,
            searchKey,
            searchTerm,
            skip: 0,
            limit: 7,
        });
        return res.data.searchParties.data;
    } catch (e) {
        throw e;
    }
}
