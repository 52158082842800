import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { State, Party, PosOrderData } from "../types";
import { select } from "features/location/actions";

const initialState: State = {
    party: { status: "unloaded" },
    rawParty: { status: "unloaded" },
    email: { status: "unloaded" },
    posOrderData: { status: "unloaded" },
};

export const partySlice = createSlice({
    name: "party",
    initialState,
    reducers: {
        reset: (state: State) => {
            state.party.status = "unloaded";
        },
        loading: (state: State) => {
            state.party.status = "loading";
        },
        loaded: (state: State, action: PayloadAction<Party>) => {
            state.party.status = "loaded";
            if (state.party.status === "loaded") {
                state.party.data = action.payload;
                state.rawParty.status = "unloaded";
                state.posOrderData = { status: "unloaded" };
            }
        },
        failed: (state: State, action: PayloadAction<Error>) => {
            state.party.status = "failed";
            if (state.party.status === "failed") {
                state.party.error = action.payload;
                state.rawParty.status = "unloaded";
            }
        },
        loadingJson: (state: State) => {
            state.rawParty.status = "loading";
        },
        jsonLoaded: (state: State, action: PayloadAction<string>) => {
            state.rawParty.status = "loaded";
            if (state.rawParty.status === "loaded") {
                state.rawParty.data = action.payload;
            }
        },
        jsonFailed: (state: State, action: PayloadAction<Error>) => {
            state.rawParty.status = "failed";
            if (state.rawParty.status === "failed") {
                state.rawParty.error = action.payload;
            }
        },
        loadingEmail: (state: State) => {
            state.email.status = "loading";
        },
        emailLoaded: (state: State, action: PayloadAction<boolean>) => {
            state.email.status = "loaded";
            if (state.email.status === "loaded") {
                state.email.data = action.payload;
            }
        },
        emailFailed: (state: State, action: PayloadAction<Error>) => {
            state.email.status = "failed";
            if (state.email.status === "failed") {
                state.email.error = action.payload;
            }
        },
        loadingPosOrderData: (state: State) => {
            state.posOrderData = { status: "unloaded" };
        },
        posOrderDataLoaded: (state: State, action: PayloadAction<PosOrderData>) => {
            state.posOrderData = {
                status: "loaded",
                data: action.payload,
            };
        },
        posOrderDataFailed: (state: State, action: PayloadAction<Error>) => {
            state.posOrderData = {
                status: "failed",
                error: action.payload,
            };
        },
    },

    extraReducers: (builder) => {
        builder.addCase(select.fulfilled, (state, s) => {
            if (state.party.status === "loaded" && state.party.data.location.id === s.meta.arg) {
                return;
            }

            state.party.status = "unloaded";
        });
    },
});

export const actionCreators = partySlice.actions;
export const reducer = partySlice.reducer;
