import { Loaded } from "common/loader";
import React, { useEffect, useState } from "react";
import { Spinner } from "sharedComponents";
import { PayloadViewer } from "sharedComponents/controls/payloadViewer";
import { PosOrderData } from "..";

export interface Props {
    locationId: string;
    isSupported: boolean;
    posOrderIds: string[];
    posOrderData: Loaded<PosOrderData>;
    getOrderData: (locationId: string, posOrderId: string, format?: string) => void;
}

export const PosOrderDataTab = ({ locationId, isSupported, posOrderIds, posOrderData, getOrderData }: Props) => {
    const [selectedPosOrderId, setSelectedPosOrderId] = useState<string | undefined>(undefined);
    const [selectedPosOrderFormat] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (isSupported && posOrderIds.length) {
            setSelectedPosOrderId(posOrderIds[0]);
        }
    }, [isSupported, posOrderIds]);

    useEffect(() => {
        if (isSupported && selectedPosOrderId && locationId) {
            getOrderData(locationId, selectedPosOrderId, selectedPosOrderFormat);
        }
    }, [isSupported, locationId, selectedPosOrderId, selectedPosOrderFormat, getOrderData]);

    if (posOrderIds.length === 0) {
        return <>No orders submitted to POS</>;
    }

    if (!isSupported) {
        return <>Not supported by this POS</>;
    }

    if (posOrderData.status === "failed") {
        return <>Failed to load POS data</>;
    }

    if (posOrderData.status === "loading" || posOrderData.status === "unloaded") {
        return <Spinner size="large" />;
    }

    return <PayloadViewer data={posOrderData.data.data} contentType={posOrderData.data.contentType} toggle={false} />;
};
