import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { getParties } from "../actions";
import { LocationParties, Props } from "../components/LocationParties";
import { getLocationParties } from "../selectors";
import { actionCreators } from "../reducers";
import { LocationPartiesFilter } from "..";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        location: { parties, filters, locationTab },
    } = state;
    const displayPage = match.params.page;
    const partiesData = getLocationParties(state);
    return {
        parties: partiesData,
        totalCount: parties.status === "loaded" ? parties.data.totalCount : 0,
        displayPage: displayPage > 0 ? displayPage * 1 : 1,
        loading: parties.status === "loading",
        filters,
        locationId: match.params["location_id"],
        selectedParty: match.params["party_id"],
        activeTab: locationTab,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, match }: RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        getLocationParties: (locationId: string, skip: number, limit: number, filters?: LocationPartiesFilter) =>
            dispatch(getParties(locationId, skip, limit, filters)),
        goToPage: (page: number) => history.push(`${page}`),
        showParty: (partyId: string, memberId?: string) =>
            match.params["party_id"]
                ? history.replace(`/${match.params.region}/${partyId}${memberId ? "/member/"+memberId : ""}`)
                : history.push(`${match.params.page}/party/${partyId}${memberId ? "/member/"+memberId : ""}`),
        setDatesFilter: (from: string, to: string) => dispatch(actionCreators.partiesFilter({ dates: { from, to } })),
        removeDatesFilter: () => dispatch(actionCreators.partiesFilter({ dates: undefined })),
        setOrderFilter: (orderId: string) => dispatch(actionCreators.partiesFilter({ orderId })),
        onActiveChange: (active: boolean) => dispatch(actionCreators.partiesFilter({ active })),
        onSearch: () => history.push(`1`),
    };
};

export const LocationPartiesContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationParties));
