import { AppState } from "app/store";
import { MemberType, MemberValue } from "features/member/types";
import { LocationValue } from "features/location";
import React from "react";
import { StartCheckedIcon } from "sharedComponents/assets/Icons";
import { GroupTabSearchResult, OrderSearchResult } from "../types";

export const getMembersIdOptions = (state: AppState) => {
    return state.search.values.status === "loaded"
        ? state.search.values.data.map((value: MemberValue, index: number) => ({
              id: value.id,
              value: value.id,
              isLast: index === state.search.values.data.length - 1,
              icon: value.memberType === MemberType.guest ? undefined : <StartCheckedIcon />,
              extraValues: [`${value.lastName}, ${value.firstName}`, value.phoneNumber, value.email].filter(
                  (val) => !!val
              ),
          }))
        : [];
};
export const getMembersNameOptions = (state: AppState) => {
    return state.search.values.status === "loaded"
        ? state.search.values.data.map((value: MemberValue) => ({
              id: value.id,
              value: `${value.lastName}, ${value.firstName}`,
              icon: value.memberType === MemberType.guest ? undefined : <StartCheckedIcon />,
              extraValues: [value.phoneNumber, value.email].filter((val) => !!val),
          }))
        : [];
};
export const getMembersPhoneOptions = (state: AppState) => {
    return state.search.values.status === "loaded"
        ? state.search.values.data.map((value: MemberValue) => ({
              id: value.id,
              value: value.phoneNumber,
              icon: value.memberType === MemberType.guest ? undefined : <StartCheckedIcon />,
              extraValues: [`${value.lastName}, ${value.firstName}`, value.email].filter((val) => !!val),
          }))
        : [];
};
export const getMembersEmailOptions = (state: AppState) => {
    return state.search.values.status === "loaded"
        ? state.search.values.data.map((value: MemberValue) => ({
              id: value.id,
              value: value.email,
              icon: value.memberType === MemberType.guest ? undefined : <StartCheckedIcon />,
              extraValues: [`${value.lastName}, ${value.firstName}`, value.phoneNumber].filter((val) => !!val),
          }))
        : [];
};

export const getLocationsOptions = (state: AppState) => {
    return state.search.values.status === "loaded"
        ? state.search.values.data.map((value: LocationValue) => ({
              id: value.id,
              value: value.displayName,
              extraValues: [value.slug],
          }))
        : [];
};

export const getOrdersTransactionOptions = (state: AppState) => {
    return state.search.values.status === "loaded" && !!state.search.values?.data[0]?.tableNumber
        ? state.search.values.data.map((value: OrderSearchResult) => ({
              id: value.id,
              value: value.transactionId,
              extraValues: [
                  new Intl.NumberFormat(value.locale, { style: "currency", currency: value.currency }).format(
                      value.amount || 0
                  ),
                  new Date(value.date).toLocaleDateString("en-GB", options),
              ],
          }))
        : [];
};

export const getGroupTabOptions = (state: AppState) => {
    return state.search.values.status === "loaded" && !!state.search.values?.data[0]?.owner
        ? state.search.values.data.map((value: GroupTabSearchResult) => ({
              id: value.id,
              value: value.id,
              extraValues: [
                  new Intl.NumberFormat(value.locale, { style: "currency", currency: value.currency }).format(
                      value.creditsTotal || 0
                  ),
                  new Date(value.dateOpened).toLocaleDateString("en-GB", options),
                  value?.members && value.members.length > 0 && value?.members[0].displayName,
              ],
          }))
        : [];
};

const options = { year: "numeric", month: "short", day: "numeric" };
export const getOrdersPartyOptions = (state: AppState) => {
    return state.search.values.status === "loaded" && !!state.search.values?.data[0]?.tableNumber
        ? state.search.values.data.map((value: OrderSearchResult) => ({
              id: value.id,
              value: value.id,
              extraValues: [
                  new Intl.NumberFormat(value.locale, { style: "currency", currency: value.currency }).format(
                      value?.paymentMethod === "GroupTab" ? value?.subTotal || 0 : value.amount || 0
                  ),
                  new Date(value.date).toLocaleDateString("en-GB", options),
                  value.tableNumber,
              ],
          }))
        : [];
};
