import { createSelector } from "@reduxjs/toolkit";
import { AppState } from "app/store";
import { DropDownOption } from "sharedComponents";
import { getLegacyRegion } from "..";
import { Region } from "../types/region";

export const getSelectedRegionState = (state: AppState) => state.region.selectedRegion.status;
export const getSelectedRegion = (state: AppState) =>
    state.region.selectedRegion.status === "loaded" ? state.region.selectedRegion.data : undefined;
export const getAvailableRegions = (state: AppState) =>
    state.region.availableRegions.status === "loaded" ? state.region.availableRegions.data : [];

export const getAvailableRegionsOptions = createSelector(
    getAvailableRegions,
    getSelectedRegion,
    (availableRegions, selectedRegion) => {
        const mappedRegion: DropDownOption[] = availableRegions.map(
            (region: Region) => ({ key: region.id, display: region.displayName } as DropDownOption)
        );
        const legacyRegionId = getLegacyRegion();
        if (legacyRegionId && !availableRegions.length) {
            mappedRegion.push({ key: legacyRegionId, display: `Default (${legacyRegionId})` } as DropDownOption);
        }
        if (selectedRegion && mappedRegion.every((r: DropDownOption) => r.key !== selectedRegion.id)) {
            mappedRegion.unshift({
                key: selectedRegion.id,
                display: selectedRegion.displayName || `unknown (${selectedRegion.id})`,
            } as DropDownOption);
        }
        return mappedRegion;
    }
);
export const isSelectedAvailable = createSelector(
    getAvailableRegions,
    getSelectedRegion,
    getSelectedRegionState,
    (availableRegions, selectedRegion, selectedStatus) =>
        selectedRegion
            ? availableRegions.length
                ? availableRegions.some((r: Region) => r.id === selectedRegion.id)
                : getLegacyRegion() === selectedRegion.id
            : selectedStatus === "failed"
            ? false
            : undefined
);
