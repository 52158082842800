import { AppState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";
import { GroupTab, PaymentStatus } from "../types";

const getGroupTab = (state: AppState) =>
    state.groupTab.groupTab.status === "loaded" ? (state.groupTab.groupTab.data as GroupTab) : undefined;

export const getGroupTabOwner = createSelector(getGroupTab, (groupTab) => groupTab && groupTab?.owner);

export const getTabTipAmount = createSelector(
    getGroupTab,
    (groupTab) => groupTab && groupTab.credits.find((c) => c.tipOnly && c.status === PaymentStatus.COMPLETE)?.amount
);
