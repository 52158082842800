import { AppDispatch } from "app/store";
import { actionCreators, GROUPTABS_FILTERS_KEY } from "../reducers";
import { LocationGroupTabResult, LocationGroupTabsFilter } from "../types";
import { fetchGroupTabs } from "../api/groupTabsApi";
import { subtractDaysFromDate } from "common/utils/dateHelper";
import { dateTimeFormat } from "sharedComponents";

export const getGroupTabs = (
    locationId: string,
    skip: number,
    limit: number,
    searchOptions?: LocationGroupTabsFilter
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(actionCreators.groupTabsLoading());

        try {
            if (searchOptions) {
                window.sessionStorage.setItem(GROUPTABS_FILTERS_KEY, JSON.stringify(searchOptions));
            } else {
                window.sessionStorage.removeItem(GROUPTABS_FILTERS_KEY);
            }
            const result: LocationGroupTabResult = await fetchGroupTabs(
                locationId,
                skip,
                limit,
                searchOptions?.ownerName || null,
                !!searchOptions?.active
                    ? dateTimeFormat.format(subtractDaysFromDate(new Date(), 2))
                    : searchOptions?.dates?.from || null,
                !!searchOptions?.active
                    ? dateTimeFormat.format(subtractDaysFromDate(new Date(), 0))
                    : searchOptions?.dates?.to || null,
                searchOptions?.active || false
            );
            dispatch(actionCreators.groupTabsLoaded(result));
        } catch (e) {
            dispatch(actionCreators.groupTabsFailed(e.message));
        }
    };
};
