import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { getGroupTabs } from "../actions";
import { LocationGroupTabs, Props } from "../components/LocationGroupTabs";
import { getLocationGroupTabs } from "../selectors";
import { actionCreators } from "../reducers";
import { LocationGroupTabsFilter } from "..";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        location: { groupTabs, groupTabfilters, locationTab },
    } = state;
    const displayPage = match.params.page;
    const groupTabsData = getLocationGroupTabs(state);

    return {
        groupTabs: groupTabsData,
        totalCount: groupTabs.status === "loaded" ? groupTabs.data.totalCount : 0,
        displayPage: displayPage > 0 ? displayPage * 1 : 1,
        loading: groupTabs.status === "loading",
        groupTabfilters,
        locationId: match.params["location_id"],
        selectedGroupTab: match.params["grouptab_id"],
        activeTab: locationTab,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, match }: RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        getLocationGroupTabs: (
            locationId: string,
            skip: number,
            limit: number,
            groupTabfilters?: LocationGroupTabsFilter
        ) => dispatch(getGroupTabs(locationId, skip, limit, groupTabfilters)),
        goToPage: (page: number) => history.push(`${page}`),
        showGroupTab: (groupTabId: string) =>
            match.params["grouptab_id"]
                ? history.replace(`/${match.params.region}/${groupTabId}`)
                : history.push(`${match.params.page}/grouptab/${groupTabId}`),
        setDatesFilter: (from: string, to: string) => dispatch(actionCreators.groupTabsFilter({ dates: { from, to } })),
        removeDatesFilter: () => dispatch(actionCreators.groupTabsFilter({ dates: undefined })),
        setOwnerFilter: (ownerName: string) => dispatch(actionCreators.groupTabsFilter({ ownerName })),
        onActiveChange: (active: boolean) => dispatch(actionCreators.groupTabsFilter({ active })),
        onSearch: () => history.push(`1`),
    };
};

export const LocationGroupTabsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationGroupTabs));
