import React, { ReactNode } from "react";
import AntModal from "antd/lib/modal/Modal";

import "./Modal.scss";

interface Props {
    isOpen: boolean;
    onClose: () => void;
    children: ReactNode;
    header?: ReactNode;
    closable?: boolean;
}

export const Modal = ({ isOpen, onClose, children, header, closable }: Props) => {
    const [visible, setVisible] = React.useState(isOpen);

    React.useEffect(() => {
        setVisible(isOpen);
    }, [isOpen]);

    const toClose = () => {
        setVisible(false);
        onClose();
    };

    return (
        <AntModal
            visible={visible}
            title={header}
            onOk={toClose}
            onCancel={toClose}
            footer={null}
            closable={closable}
            className="mnu-modal"
            width={580}
        >
            {children}
        </AntModal>
    );
};
