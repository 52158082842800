import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { rawParty } from "../actions";
import { RawPartyTab, Props } from "../components/RawPartyTab";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        party: { rawParty, party },
    } = state;
    if (party.status === "loaded") {
        return {
            rawPartyJson: rawParty.status === "loaded" ? rawParty.data : undefined,
            partyId: party.data.id,
            locationId: party.data.location.id,
            loading: rawParty.status === "loading",
        };
    } else {
        return {
            loading: rawParty.status === "loading",
        };
    }
};

const mapDispatchToProps = (dispatch: AppDispatch, { match }: RouteComponentProps<any>): FunctionProps<Props> => {
    return {
        getRawParty: (partyId: string, locationId: string) => dispatch(rawParty(partyId, locationId)),
    };
};

export const RawPartyTabContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(RawPartyTab));
