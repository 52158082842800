import React from "react";
import { LoadStatus, SaveStatus } from "common/loader/types";
import "./PageLoading.scss";
import { Trigger } from "common/loader/Trigger";
import { Spinner } from "sharedComponents";

export interface PageLoadingDataProps {
    loadStatus: LoadStatus;
    saveStatus?: SaveStatus;
}

export type PageLoadingProps<T> =
    | ({ loadStatus: "loaded"; saveStatus?: SaveStatus } & PageLoadingActionProps & T)
    | ({ loadStatus: LoadStatus; saveStatus?: SaveStatus } & PageLoadingActionProps & Partial<T>);

export interface PageLoadingActionProps {
    fetch?: () => void;
}

export const withLoader = <P extends {}>(Component: React.ComponentType<P>): React.SFC<PageLoadingProps<P>> => ({
    loadStatus,
    saveStatus,
    fetch,
    ...props
}: PageLoadingProps<P>) => (
    <Trigger action={fetch!}>
        {loadStatus === "unloaded" && fetch!() ? null : null}
        {loadStatus === "loaded" && (
            <>
                <Component {...(props as any)} saveStatus={saveStatus} />
            </>
        )}
        {loadStatus === "failed" && <div onClick={fetch}>Failed</div>}
        {loadStatus === "loading" && (
            <div className="loader__container loader__container--overlay">
                <Spinner />
            </div>
        )}
    </Trigger>
);

export const withFailed = <P extends {}>(Component: React.ComponentType<P>): React.SFC<PageLoadingProps<P>> => ({
    loadStatus,
    saveStatus,
    ...props
}: PageLoadingProps<P>) => (loadStatus === "failed" ? <div>Failed</div> : <Component {...(props as any)} />);
