import React from "react";
import { Input as AntInput } from "antd";
import { InputProps } from "antd/lib/input";
import classNames from "classnames";
import "./Input.scss";
import { Spinner, Text } from "..";

interface Props extends InputProps {
    isError?: boolean;
    searchMode?: boolean;
    allowClear?: boolean;
    loading?: boolean;
    defaultValue?: string;
    onSelectHandle?: (value: string) => void;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
}

function getAntProps(props: Props) {
    let passProps = { ...props };
    delete passProps.searchMode;
    delete passProps.isError;
    delete passProps.loading;
    delete passProps.onSelectHandle;
    return passProps;
}

export const Input: React.FC<Props> = React.forwardRef((props: Props, ref: React.Ref<any>) => {
    const [valueExists, setValueExists] = React.useState(!!props.value);
    function onChange(ev: React.ChangeEvent<HTMLInputElement>) {
        setValueExists(!!ev.target.value);
        props.onChange && props.onChange(ev);
    }

    function onSelect(event: any) {
        const value = event.target ? event.target.value : event;
        if (props.onSelectHandle) {
            props.onSelectHandle(value);
        }
    }
    let additionalProps = {};
    if (props.searchMode) {
        additionalProps = {
            onSearch: onSelect,
        };
    }
    const UseInput = props.searchMode ? AntInput.Search : AntInput;
    return (
        <>
            <UseInput
                {...getAntProps(props)}
                {...additionalProps}
                onBlur={onSelect}
                ref={ref}
                suffix={props.loading ? <Spinner size="small" /> : null}
                onChange={(e) => {
                    onChange(e);
                }}
                className={classNames("input-element", { error: props.isError, filled: valueExists }, props.className)}
            />
            {!!props.error && (
                <Text preset="body" className="input-error-message">
                    {props.error}
                </Text>
            )}
        </>
    );
});
