import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { MemberPage, Props } from "../components/MemberPage";
import { PageLoadingProps, withFailed } from "common/loader/withLoader";
import { RouteComponentProps, withRouter } from "react-router";
import { actionCreators } from "../reducers";

const mapStateToProps = (state: AppState): DataProps<PageLoadingProps<Props>> => {
    const {
        member: { info, memberTab },
    } = state;

    return {
        loadStatus: info.status,
        member: info.status === "loaded" ? info.data : {},
        activeTab: memberTab,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, { history }: RouteComponentProps<any>): FunctionProps<Props> => {
    return {
        setActiveTab: (activeTab: number) => {
            dispatch(actionCreators.setMemberTab(activeTab));
            history.push(`1`);
        },
    };
};

export const MemberPageContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(withFailed(MemberPage)));
