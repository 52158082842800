import React from "react";
import { IPayment, Refund } from "..";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import { Spinner, Divider, DateFormatted } from "sharedComponents";
import { Currency, Text } from "sharedComponents";
import classNames from "classnames";
import "../assets/TransactionTab.scss";
import { NoData } from "./NoData";

export interface Props {
    loading: boolean;
    payments?: IPayment[];
    currency?: string;
    locale?: string;
}

function isRefund(payment: IPayment) {
    return (payment as Refund).refundId !== undefined;
}

export const TransactionTab = ({ loading, payments, locale, currency }: Props) =>
    loading ? (
        <Spinner size="large" />
    ) : (
        <>
            {!payments ? (
                <NoData />
            ) : (
                payments.map((payment, index) => (
                    <React.Fragment key={`transaction_row_${payment.transactionId}_${index}`}>
                        {!!index && <Divider />}
                        <div className={classNames("party-transaction", payment.statusSeverity.toLowerCase())}>
                            <div className="party-transaction--title">
                                <Text
                                    preset="subtitle"
                                    mode="bold"
                                    value={`Transaction ${isRefund(payment) ? "refunded" : payment.status}`}
                                />
                            </div>
                            <KeyValueRow
                                item={{
                                    label: isRefund(payment) ? "Amount refunded" : "Amount charged",
                                    value: (
                                        <Currency
                                            locale={locale!}
                                            currency={currency!}
                                            value={payment.transactionTotal}
                                        />
                                    ),
                                }}
                            />
                            <KeyValueRow
                                item={{
                                    label: "Transaction ID",
                                    value: (
                                        <Text mode={["link", "bold"]}>
                                            <a target="_blank" rel="noopener noreferrer" href={payment.transactionLink}>
                                                {payment.transactionId || ""}
                                            </a>
                                        </Text>
                                    ),
                                }}
                            />
                            {!isRefund(payment) && (
                                <KeyValueRow item={{ label: "Payment method", value: payment.paymentMethod }} />
                            )}
                            <KeyValueRow item={{ label: "Date", value: <DateFormatted value={payment.date} /> }} />
                            <KeyValueRow item={{ label: "Status", value: payment.status }} />
                            {!!payment.error && (
                                <KeyValueRow item={{ label: "Failure reason", value: payment.error?.message }} />
                            )}
                        </div>
                    </React.Fragment>
                ))
            )}
        </>
    );
