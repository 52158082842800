import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { searchMembers, searchLocations, searchParties, searchGroupTabs } from "../api";
import { MemberValue } from "features/member/types";
import { LocationValue } from "features/location";
import { GroupTabSearchResult, OrderSearchResult } from "../types";

export function searchMember(searchTerm: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.loading());

        try {
            const {
                search: { searchKey },
            } = getState();
            const result: MemberValue[] = await searchMembers(searchKey, searchTerm);
            dispatch(actionCreators.loaded(result));
        } catch (e) {
            dispatch(actionCreators.failed(e.message));
        }
    };
}
export function searchVenue(searchValue: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.loading());

        try {
            const result: LocationValue[] = await searchLocations(searchValue);
            dispatch(actionCreators.loaded(result));
        } catch (e) {
            dispatch(actionCreators.failed(e.message));
        }
    };
}
export function searchOrders(location: string, searchKey: string, searchValue: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.loading());

        try {
            const result: OrderSearchResult[] = await searchParties(location, searchKey, searchValue);
            dispatch(actionCreators.loaded(result));
        } catch (e) {
            dispatch(actionCreators.failed(e.message));
        }
    };
}

export function searchGroupTab(location: string, searchValue: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.loading());

        try {
            const result: GroupTabSearchResult[] = await searchGroupTabs(location, searchValue);
            dispatch(actionCreators.loaded(result));
        } catch (e) {
            dispatch(actionCreators.failed(e.message));
        }
    };
}
