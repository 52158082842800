import React from "react";
import featureConfig from "app/featureConfig";
import { Page } from "sharedComponents";
import { SearchContainer } from "features/search/containers/SearchContainer";
import { NavIcon } from "common/assets/components/Icons";
import { Nav } from "sharedComponents";
import { RegionSelector } from "features/region/components/RegionSelector";
import { region } from "features/region";

export interface Props {
    disableSearch: boolean;
    logout: () => void;
}

export const HomePage = ({ logout, disableSearch }: Props) => (
    <Page>
        <Nav
            icon={<NavIcon />}
            mainAction={
                <>
                    <RegionSelector />
                    <SearchContainer disabled={disableSearch} />
                </>
            }
            optionLabel1="log out"
            optionFunc1={logout}
        />
        {region.hasAccess() ? featureConfig.routes.locationRoutes : <div>Access denied - cannot access region</div>}
    </Page>
);
