import React, { useEffect } from "react";
import { LocationValue } from "../types";
import { Text, Section, Spinner, DateFormatted, Item, InfoSection } from "sharedComponents";

export interface Props {
    location?: LocationValue;
    loading: boolean;
    locationId: string;
    getLocation: (locationId: string) => void;
}

export const LocationInfoSection = ({ location, loading, locationId, getLocation }: Props) => {
    useEffect(() => {
        getLocation(locationId);
    }, [getLocation, locationId]);

    return (
        <Section className="location-info">
            {loading ? (
                <Spinner size="large" />
            ) : location ? (
                <InfoSection title={"Location"}>
                    <Item label="Name">
                        <Text preset="subtitle" mode="bold" value={location.displayName} />
                    </Item>
                    <>
                        <Item label="Group" value={location.group?.displayName || "-"} />
                        <Item label="Pos" value={location.postype || "-"} />
                        <Item label="Created">
                            <Text mode="bold">
                                <DateFormatted value={location.dateCreated!} />
                            </Text>
                        </Item>
                    </>
                    <>
                        <Item label=" " value=" " />
                        <Item label="ABN" value={location.companyDetails?.abn || "-"} />
                        <Item label="Trading Name" value={location.companyDetails?.registeredName || "-"} />
                    </>
                </InfoSection>
            ) : (
                <>
                    <div className="info-section__title">
                        <Text preset="header" mode="label" value="Location" />
                    </div>
                    <div className="info-section__info">
                        <Text preset="body" mode="label" value="No location data" />
                    </div>
                </>
            )}
        </Section>
    );
};
