import { AppState } from "app/store";
import { LocationGroupTabData, LocationPartyData, LocationValue } from "../types";
import { createSelector } from "@reduxjs/toolkit";
import { getPartyLocale } from "features/party/utils/partyLocaleCache";
import { PaymentMethod } from "common/types";

const getParties = (state: AppState) =>
    state.location.parties.status === "loaded" ? state.location.parties.data.data : null;

export const getLocationInfo = (state: AppState) => state.location.info.data as LocationValue;

export const getSupportedPosOperationMap = createSelector(getLocationInfo, (location) =>
    !location
        ? {}
        : location.posMetadata.operations.reduce((map, op) => {
              map[op.name] = op.supported;
              return map;
          }, {} as PosOperationSupportMap)
);

export type PosOperationSupportMap = { [operationName: string]: boolean | undefined };

export const getLocationParties = createSelector(getParties, (parties) => {
    return parties
        ? parties.map((value: LocationPartyData) => ({
              id: value.id,
              originalData: value,
              success: value.closeReason && value.closeReason === "completed",
              field1: value.memberName,
              field2: value.tableNumber,
              field3: value.date,
              field4:
                  value?.paymentMethod?.toUpperCase() === PaymentMethod.GROUP_TAB
                      ? "Group Tab"
                      : value?.amount === null
                      ? "N/A"
                      : getPartyLocale(value.locale, value.currency).formatCurrency(value.amount || 0),
          }))
        : [];
});

const getGroupTabs = (state: AppState) =>
    state.location.groupTabs.status === "loaded" ? state.location.groupTabs.data.data : null;

export const getLocationGroupTabs = createSelector(getGroupTabs, (groupTabs) => {
    return groupTabs
        ? groupTabs.map((value: LocationGroupTabData) => ({
              id: value.id,
              originalData: value,
              success: value.status.toLocaleLowerCase() === "closed" && !!value?.dateClosed,
              field1: value.tabName,
              field2:
                  value.members.length > 0
                      ? value.members[0].displayName || value.ownerName || ""
                      : value.ownerName || "",
              field3:
                  value.status.toLocaleLowerCase() === "closed" && !!value?.dateClosed
                      ? value.dateOpened + "," + value.dateClosed
                      : value.dateOpened,
              field4:
                  value?.creditsTotal === null
                      ? "N/A"
                      : getPartyLocale(value.locale, value.currency).formatCurrency(value.creditsTotal || 0),
          }))
        : [];
});
