import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { PartyPage, Props } from "../components/PartyPage";
import { isSingleUser, getPartyMember } from "../selectors";
import { PageLoadingProps, withFailed } from "common/loader/withLoader";
import { loadPartyLocation } from "../components/loadPartyLocation";

const mapStateToProps = (state: AppState): DataProps<PageLoadingProps<Props>> => {
    const {
        party: { party },
    } = state;
    const partyMembers = getPartyMember(state);

    return {
        loadStatus: party.status,
        noTransactions: !partyMembers || !partyMembers.payments.length,
        singleUser: isSingleUser(state),
        notGroupTabAdmin:
            party.status === "loaded" &&
            !!party?.data?.groupTab &&
            !!partyMembers &&
            partyMembers.id !== party.data.groupTab.owner,
    };
};

export const PartyPageContainer = connect(mapStateToProps)(loadPartyLocation(withFailed(PartyPage)));
