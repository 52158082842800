import React, { useEffect } from "react";
import { Spinner, DateFormatted, StartandEndDateFormatted } from "sharedComponents";
import { MemberGroupTabData } from "..";
import { Tables } from "sharedComponents/dataDisplay/tables";
import { LayoutType, FourFields } from "sharedComponents/dataDisplay/tables/types";
import "../assets/MemberParties.scss"; //replace this
import { RowGroupTabName } from "./RowGroupTabName";

export interface Props {
    memberId: string;
    totalCount: number;
    displayPage: number;
    loading: boolean;
    selectedGroupTab?: string;
    groupTabs: MemberGroupTabData[];
    getMemberGroupTabs: (memberId: string, skip: number, limit: number) => void;
    goToPage: (page: number) => void;
    showGroupTab: (groupTabId: string) => void;
    activeTab: number;
}

const columnData = [
    {
        title: "Location",
        render: (locationName: string) => <span>{locationName}</span>,
    },
    {
        title: "Tab Name",
        render: (tabName: string, item: FourFields) => (
            <RowGroupTabName tabName={tabName} groupTabData={item.originalData} />
        ),
    },
    {
        title: "Duration",
        render: (date: string, item: FourFields) =>
            !!item?.success ? (
                <StartandEndDateFormatted start={date.split(",")[0]} end={date.split(",")[1]} />
            ) : (
                <DateFormatted value={date} />
            ),
    },
    {
        title: "Total charged",
    },
];

const ROWS_PER_PAGE = 9;

export const MemberGroupTabs = ({
    groupTabs,
    loading,
    displayPage,
    memberId,
    totalCount,
    getMemberGroupTabs,
    goToPage,
    showGroupTab,
    selectedGroupTab,
    activeTab,
}: Props) => {
    useEffect(() => {
        if (activeTab === 1) {
            !!memberId && getMemberGroupTabs(memberId, ROWS_PER_PAGE * (displayPage - 1), ROWS_PER_PAGE);
        }
    }, [getMemberGroupTabs, memberId, displayPage, activeTab]);

    return (
        <>
            {loading && !groupTabs.length ? (
                <Spinner size="large" />
            ) : (
                <Tables
                    layoutType={LayoutType.FOUR_COLUMNS}
                    dataSource={groupTabs}
                    selected={selectedGroupTab}
                    columnData={columnData}
                    startColumn={selectedGroupTab ? 1 : 0}
                    goToPage={goToPage}
                    page={displayPage}
                    selectRow={(row: any) => showGroupTab(row.id)}
                    pageSize={ROWS_PER_PAGE}
                    showPagination={!selectedGroupTab}
                    totalCount={totalCount}
                />
            )}
        </>
    );
};
