import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    State,
    LocationValue,
    LocationPartyResult,
    LocationPartiesFilter,
    LocationGroupTabsFilter,
    LocationGroupTabResult,
} from "../types";
import { dateTimeFormat } from "sharedComponents";
import { subtractDaysFromDate } from "common/utils/dateHelper";

export const PARTIES_FILTERS_KEY = "PARTIES_FILTERS_KEY";
export const GROUPTABS_FILTERS_KEY = "GROUPTABS_FILTERS_KEY";

const Location_TAB_KEY = "Location_TAB_KEY";

const initialState: State = {
    info: { status: "unloaded" },
    parties: { status: "unloaded" },
    groupTabs: { status: "unloaded" },
    filters: JSON.parse(window.sessionStorage.getItem(PARTIES_FILTERS_KEY) || "false") || {
        dates: { from: dateTimeFormat.format(new Date()), to: dateTimeFormat.format(new Date()) },
    },
    groupTabfilters: JSON.parse(window.sessionStorage.getItem(GROUPTABS_FILTERS_KEY) || "false") || {
        dates: {
            from: dateTimeFormat.format(subtractDaysFromDate(new Date(), 3)),
            to: dateTimeFormat.format(subtractDaysFromDate(new Date(), 3)),
        },
        active: true,
    },
    locationTab: JSON.parse(window.localStorage.getItem(Location_TAB_KEY) || "1"),
};

export const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        infoLoading: (state: State) => {
            state.info.status = "loading";
        },
        infoLoaded: (state: State, action: PayloadAction<LocationValue>) => {
            state.info.status = "loaded";
            if (state.info.status === "loaded") {
                state.info.data = action.payload;
            }
        },
        infoFailed: (state: State, action: PayloadAction<string>) => {
            state.info.status = "failed";
            if (state.info.status === "failed") {
                state.info.error = action.payload;
            }
        },
        partiesLoading: (state: State) => {
            state.parties.status = "loading";
        },
        partiesLoaded: (state: State, action: PayloadAction<LocationPartyResult>) => {
            state.parties.status = "loaded";
            if (state.parties.status === "loaded") {
                state.parties.data = action.payload;
            }
        },
        partiesFailed: (state: State, action: PayloadAction<string>) => {
            state.parties.status = "failed";
            if (state.parties.status === "failed") {
                state.parties.error = action.payload;
            }
        },
        partiesFilter: (state: State, action: PayloadAction<LocationPartiesFilter>) => {
            if (!state.filters) {
                state.filters = action.payload;
            } else {
                state.filters = {
                    ...state.filters,
                    ...action.payload,
                };
            }
        },
        groupTabsLoading: (state: State) => {
            state.groupTabs.status = "loading";
        },
        groupTabsLoaded: (state: State, action: PayloadAction<LocationGroupTabResult>) => {
            state.groupTabs.status = "loaded";
            if (state.groupTabs.status === "loaded") {
                state.groupTabs.data = action.payload;
            }
        },
        groupTabsFailed: (state: State, action: PayloadAction<string>) => {
            state.groupTabs.status = "failed";
            if (state.groupTabs.status === "failed") {
                state.groupTabs.error = action.payload;
            }
        },
        groupTabsFilter: (state: State, action: PayloadAction<LocationGroupTabsFilter>) => {
            if (!state.groupTabfilters) {
                state.groupTabfilters = action.payload;
            } else {
                state.groupTabfilters = {
                    ...state.groupTabfilters,
                    ...action.payload,
                };
            }
        },
        setLocationTab: (state: State, action: PayloadAction<number>) => {
            window.localStorage.setItem(Location_TAB_KEY, JSON.stringify(action.payload));
            state.locationTab = action.payload;
        },
    },
});

export const actionCreators = locationSlice.actions;
export const reducer = locationSlice.reducer;
