import React from "react";
import { Text } from "../../controls";
import "./InfoSection.scss";

interface Props {
    title: string;
    children: React.ReactNode[];
}

export const InfoSection = ({ title, children }: Props) => {
    return (
        <div className="info-section">
            <div className="info-section__title">
                <Text preset="header" mode="label">
                    {title}
                </Text>
            </div>

            <div className="info-section__info">{children[0]}</div>
            <div className="info-section__row">
                <div className="info-section__row__left-panel">{children[1]}</div>
                <div className="info-section__row__right-panel">{children[2]}</div>
            </div>
        </div>
    );
};
