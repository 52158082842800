import React from "react";
import { Content, Section } from "sharedComponents";
import { MemberInfoContainer } from "../../member/containers/MemberInfoContainer";
import { BreadcrumbContainer } from "features/breadcrumb/containers/BreadcrumbContainer";
import { MemberPartiesContainer } from "features/member/containers/MemberPartiesContainers";
import { OrderDetailsContainer } from "../containers/OrderDetailsContainer";
import { Tabs } from "sharedComponents/dataDisplay/tabs";
import { PaymentTabContainer } from "../containers/PaymentTabContainer";
import { TransactionTabContainer } from "../containers/TransactionTabContainer";
import { OrderItemsContainer } from "../containers/OrderItemsContainer";
import { BillSplitTabContainer } from "../containers/BillSplitTabContainer";
import { PartyDiagnosticsSection } from "./PartyDiagnosticsSection";

export interface Props {
    singleUser: boolean;
    noTransactions: boolean;
    notGroupTabAdmin: boolean;
}

export const PartyPage = ({ noTransactions, singleUser, notGroupTabAdmin }: Props) => (
    <>
        <Content>
            <>
                <MemberInfoContainer />
                <Section>
                    <MemberPartiesContainer />
                </Section>
            </>
            <>
                <OrderDetailsContainer />
                <Section>
                    <Tabs
                        tabPanes={[
                            {
                                content: <PaymentTabContainer />,
                                label: "Payment",
                            },
                            {
                                content: <TransactionTabContainer />,
                                label: "Transaction",
                                disabled: noTransactions || notGroupTabAdmin,
                            },
                            {
                                content: <BillSplitTabContainer />,
                                label: "Bill Split",
                                disabled: singleUser || notGroupTabAdmin,
                            },
                        ]}
                    />
                </Section>
                <OrderItemsContainer />
                <PartyDiagnosticsSection />
            </>
        </Content>
        <BreadcrumbContainer />
    </>
);
