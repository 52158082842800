import React from "react";
import { RouteComponentProps } from "react-router";
import { region } from "..";
import { Spin } from "antd";
import { HomePageContainer } from "features/login/containers/HomePageContainer";

export const withRegion = <P extends Object>(
    Component: React.ComponentType<P>
): React.SFC<P & RouteComponentProps<any>> => (props) => {
    const hasAccess = region.hasAccess();
    if (hasAccess === true) {
        return <Component {...props} />;
    } else if (hasAccess === false) {
        return <HomePageContainer />;
    }
    return (
        <div className="loader__container loader__container--overlay">
            <Spin />
        </div>
    );
};
