import { Party } from "./index";

export class PartyLocale {
    private currencyFormat: Intl.NumberFormat;
    private rawCurrencyFormat: Intl.NumberFormat;

    static fromParty(menuData: Party) {
        return new PartyLocale(menuData.locale, menuData.currency);
    }

    constructor(public locale: string, public currency: string) {
        this.currencyFormat = new Intl.NumberFormat(this.locale, {
            style: "currency",
            currency: this.currency,
        });

        // Keep all the other formatting options from currency, accept the
        // style that would include the currency symbol
        // (some currencies don't have decimal places, etc, so best inherit)
        this.rawCurrencyFormat = new Intl.NumberFormat(this.locale, {
            ...this.currencyFormat.resolvedOptions(),
            style: "decimal",
        });
    }

    formatCurrency(value: number | string, includeSymbol: boolean = true) {
        if (typeof value === "string" && value.indexOf("$") === 0) {
            value = value.substr(1);
        }

        if (includeSymbol) {
            return this.currencyFormat.format(value as any);
        }

        return this.rawCurrencyFormat.format(value as any);
    }

    formatCurrencyToParts(value: number | string, includeSymbol: boolean = true) {
        if (includeSymbol) {
            return this.currencyFormat.formatToParts(value as any);
        }

        return this.rawCurrencyFormat.formatToParts(value as any);
    }
}
