import React from "react";
import { Section, Spinner, Text } from "sharedComponents";
import "../assets/LocationFeaturesSection.scss";

export interface Props {
    loading: boolean;
    flags: string[];
}

export const LocationFeaturesSection = ({ loading, flags }: Props) => (
    <Section className="location-features">
        {loading ? (
            <Spinner size="large" />
        ) : (
            <>
                <div className="info-section__title">
                    <Text preset="header" mode="label" value="Features" />
                </div>
                <div className="location-features--content">
                    {flags.map((flag) => (
                        <div key={flag} className="location-features--content--row">
                            <Text mode="bold" value={flag} />
                        </div>
                    ))}
                </div>
            </>
        )}
    </Section>
);
