import { opsApi } from "common/api";

type GraphQLPayload = { emailGroupTabInvoice: boolean };

//backend api is not ready for this.
const emailInvoiceQuery = `mutation($groupTabId: ID!, $memberId: ID!) {
    emailGroupTabInvoice(groupTabId: $groupTabId, memberId: $memberId)
}`;

export const emailInvoice = async (groupTabId: string, memberId: string) => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(emailInvoiceQuery, { groupTabId, memberId });
        return res.data.emailGroupTabInvoice;
    } catch (e) {
        throw e;
    }
};
