import { opsApi } from "common/api";
import { getGroupTabFields } from ".";
import { GroupTab } from "../types";

type GraphQLPayload = { closedGroupTab: GroupTab };

export const getClosedGroupTab = async (groupTabId: string) => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(getClosedGroupTabQuery, { groupTabId });
        return res.data.closedGroupTab || {};
    } catch (e) {
        throw e;
    }
};

const getClosedGroupTabQuery = `query ($groupTabId: ID!) {
    closedGroupTab(groupTabId: $groupTabId) {
        ${getGroupTabFields}
    }
}`;
