import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { Search, Props } from "../components/Search";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { actionCreators } from "../reducers";

interface OwnProps extends RouteComponentProps<any> {
    disabled: boolean;
}

const mapStateToProps = (state: AppState, { disabled }: OwnProps): DataProps<Props> => {
    const {
        search: { searchTab },
    } = state;
    return {
        activeTab: searchTab,
        disabled,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        setActiveTab: (activeTab: number) => dispatch(actionCreators.setSearchTab(activeTab)),
    };
};

export const SearchContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(Search));
