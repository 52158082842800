import { trackEvent } from "common/appInsights/AppInsights";
import React from "react";
import { Autocomplete, AutoCompleteOption, Text } from "sharedComponents";

export interface Props {
    options: AutoCompleteOption[];
    loading?: boolean;
    search: (value: string, force?: boolean) => void;
    select: (value: string) => void;
}

export const SearchLocations = ({ search, select, options, loading }: Props) => {
    return (
        <div className="search__body">
            <Text className="search__title" mode="bold" preset="subtitle" value="Search for locations" />
            <Text
                className="search__description"
                preset="body"
                mode="block"
                value="Find locations by searching for the display name or location slug."
            />
            <Autocomplete
                loading={loading}
                options={options}
                onSearch={(value, force) => search(value, force)}
                onSelect={(value) => {
                    select(value);
                    trackEvent({
                        name: "SEARCH_USED",
                        properties: {
                            location_id: value,
                        },
                    });
                }}
                placeholder={"Location name or slug"}
            />
        </div>
    );
};
