import React from 'react';
import { reducer } from "./reducers";
import { Route } from 'react-router';
import { FeatureConfiguration } from "app/featureConfig";
import { PartyPageContainer } from './containers/PartyPageContainer';

export * from "./types";

export default function (config: FeatureConfiguration) {
  config.reducers.party = reducer;

  config.routes.locationRoutes.push(
    <Route
      path={["/:region/member/:member_id/:page?/party/:party_id", "/:region/location/:location_id/:page?/party/:party_id", "/:region/location/:location_id/:page?/party/:party_id/member/:member_id"]}
      exact
      key="party"
      component={PartyPageContainer} />
  )
}
