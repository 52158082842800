import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { fetchPosOrderData } from "../api/posOrderData";
import { PosOrderData } from "..";

export function posOrderData(locationId: string, posOrderId: string, format?: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.loadingPosOrderData());

        try {
            const result: PosOrderData = await fetchPosOrderData(locationId, posOrderId, format);
            dispatch(actionCreators.posOrderDataLoaded(result));
        } catch (e) {
            dispatch(actionCreators.posOrderDataFailed(e as Error));
        }
    };
}
