export const timeOptions: Intl.DateTimeFormatOptions = { hour: "numeric", minute: "numeric", hour12: true };

export const formatDate = (date: Date) => {
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - offset * 60 * 1000);
    return date.toISOString().split("T")[0];
};

export const formatTime = (time: Date) => {
    return new Intl.DateTimeFormat("en-AU", timeOptions).format(time).replace(",", "").toUpperCase();
};
