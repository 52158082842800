import React from "react";
import { Radio } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { Text } from "sharedComponents";
import classNames from "classnames";
import "./RadioButtons.scss";

interface Props {
    selectedValue?: any;
    defaultSelectedValue?: any;
    items: RadioButtonProps[];
    handleChange?: (value: any) => void;
    className?: string;
}

export interface RadioButtonProps {
    label?: string | React.ReactNode;
    value: any;
    data?: any;
}

export const RadioButtons = ({ className, selectedValue, defaultSelectedValue, items, handleChange }: Props) => {
    const [selected, setSelected] = React.useState<string | undefined>(defaultSelectedValue);

    function handleOnChange(e: RadioChangeEvent) {
        setSelected(e.target.value);
        handleChange && handleChange(e.target.value);
    }
    return (
        <Radio.Group
            onChange={handleOnChange}
            value={selectedValue !== undefined ? selectedValue : selected}
            className={classNames("radio-buttons", className)}
        >
            {items.map((item) => (
                <Radio key={`Radio-${item.value}`} value={item.value}>
                    <Text preset="body">{item.label || item.value}</Text>
                </Radio>
            ))}
        </Radio.Group>
    );
};
