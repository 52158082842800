import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { SearchOrders, Props } from "../components/SearchOrders";
import { AppDispatch, AppState } from "app/store";
import { searchOrders, searchVenue } from "../actions";
import { actionCreators } from "../reducers";
import { getLocationsOptions, getOrdersTransactionOptions, getOrdersPartyOptions } from "../selectors";
import { withRouter, RouteComponentProps } from "react-router";
import { region } from "features/region";

interface OwnProps {
    onSelectComplete: () => void;
}

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        search: { values, searchOrderKey },
    } = state;
    const locationOptions = getLocationsOptions(state);
    const ordersOptions =
        searchOrderKey === "partyId" ? getOrdersPartyOptions(state) : getOrdersTransactionOptions(state);
    return {
        locationOptions,
        ordersOptions,
        searchKey: searchOrderKey,
        loading: values.status === "loading",
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, onSelectComplete }: OwnProps & RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        searchLocation: (value: string, force?: boolean) => {
            if (value.length < 2) {
                dispatch(actionCreators.loaded([]));
                return;
            }
            // Search should happen only on demand and not on change
            force && dispatch(searchVenue(value));
        },
        searchOrders: (location: string, searchKey: string, value: string, force?: boolean) => {
            if (value.length < 2) {
                dispatch(actionCreators.loaded([]));
                return;
            }
            // Search should happen only on demand and not on change
            force && dispatch(searchOrders(location, searchKey, value));
        },
        select: (location: string, party: string) => {
            onSelectComplete();
            history.push(`/${region.getActiveRegion().id}/location/${location}/1/party/${party}`);
        },
        setSearchKey: (key: string) => dispatch(actionCreators.setSearchOrderKey(key)),
        resetOrders: () => dispatch(actionCreators.loaded([])),
    };
};

export const SearchOrdersContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchOrders));
