import { configureStore, ThunkAction, Action, ThunkDispatch, AnyAction, MiddlewareAPI } from "@reduxjs/toolkit";
import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";
import featureConfig from "./featureConfig";

export const history = createBrowserHistory({
    basename: "/",
});
export interface RoutesConfiguration {
    locationRoutes: React.ReactNode[];
}

export const store = configureStore({
    reducer: {
        ...featureConfig.reducers,
        router: connectRouter(history),
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().prepend(...featureConfig.middleware),
    devTools: true,
});

export type AppState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action<string>>;

export type ThunkApi = {
    dispatch: AppDispatch;
    state: AppState;
};

export type AppAction<T> = AnyAction | ThunkAction<T, AppState, void, AnyAction>;
export type AppDispatch = ThunkDispatch<AppState, void, AnyAction>;
export type AppMiddleware = MiddlewareAPI<AppDispatch, AppState>;
