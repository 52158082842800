export interface ColumnSource {
    title?: string;
    render?: any;
}

export interface ThreeFields<T = any> {
    id?: string;
    originalData?: T;
    field1: string | T;
    field2: string | T;
    field3: string | T;
}

export interface FourFields<T = any> extends ThreeFields {
    field4: string | T;
    success?: boolean | T;
}

export type Columns<T = any> = ThreeFields<T> | FourFields<T>;

export enum LayoutType {
    THREE_COLUMNS = "DATA/THREE_COLUMNS",
    FOUR_COLUMNS = "DATA/FOUR_COLUMNS",
    FOUR_COLUMNS_WITH_BORDER = "DATA/FOUR_COLUMNS_WITH_BORDER",
}
