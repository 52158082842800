import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";

import { CallbackPage } from "../components/CallBackPage";

export interface OwnProps {
    auth: any;
}

const mapDispatchToProps = (dispatch: any, { auth, history, location }: OwnProps & RouteComponentProps<any>) => ({
    handleAuthentication: () => {
        if (/access_token|id_token|error/.test(location.hash)) {
            auth.handleAuthentication(history, "dashboard");
        }
    },
});

export const CallbackPageContainer = connect(null, mapDispatchToProps)(CallbackPage);
