import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { PosHttpArchivePage, Props } from "../components/PosHttpArchivePage";
import { RouteComponentProps, withRouter } from "react-router";
import { loadHar } from "../actions/loadHar";

const mapStateToProps = (
    state: AppState,
    { match }: RouteComponentProps<any>
): DataProps<Props> => {
    const harState = state.instrumentation.har;

    return {
        har: harState,
        operationId: match.params['operationId']
    }
};

const mapDispatchToProps = (
    dispatch: AppDispatch
): FunctionProps<Props> => {
    return {
        load: (operationId: string) => {
            dispatch(loadHar(operationId));
        },
    };
};
export const PosHttpArchivePageContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PosHttpArchivePage));
