import React from "react";
import { PayloadViewer } from "sharedComponents/controls/payloadViewer";

export interface Props {
    rawPartyJson?: string;
    partyId?: string;
    locationId?: string;
    loading?: boolean;
    getRawParty: (partyId: string, locationId: string) => void;
}

export const RawPartyTab = ({ loading, rawPartyJson, partyId, locationId, getRawParty }: Props) => {
    function fetchJson() {
        getRawParty(partyId!, locationId!);
    }
    return <PayloadViewer data={rawPartyJson} contentType="application/json" loading={loading} onOpen={fetchJson} />;
};
