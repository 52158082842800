import React from "react";
import { Section } from "sharedComponents";
import { PayloadViewer } from "sharedComponents/controls/payloadViewer";

export interface Props {
    rawGroupTabJson?: string;
    groupTabId?: string;
    locationId?: string;
    loading?: boolean;
    getRawGroupTab: (groupTabId: string, locationId: string) => void;
}

export const RawGroupTabSection = ({ loading, rawGroupTabJson, groupTabId, locationId, getRawGroupTab }: Props) => {
    const fetchJson = () => {
        getRawGroupTab(groupTabId!, locationId!);
    };

    return (
        <Section>
            <PayloadViewer
                label={"Group Tab json"}
                contentType="application/json"
                data={rawGroupTabJson}
                loading={loading}
                onOpen={fetchJson}
            />
        </Section>
    );
};
