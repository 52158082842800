import { connect } from "react-redux";
import { AppState, AppDispatch } from "app/store";
import { DataProps, FunctionProps } from "types/utils";
import { HomePage, Props } from "../components/HomePage";
import { auth } from "common/auth";
import { withRouter } from "react-router";
import { isSelectedAvailable } from "features/region/selectors";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    return {
        disableSearch: isSelectedAvailable(state) === false,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch): FunctionProps<Props> => {
    return {
        logout: () => auth.logout(),
    };
};

export const HomePageContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage));
