import { opsApi } from "common/api";
import { MemberValue } from "features/member/types";

type GraphQLPayload = { member: MemberValue };

const getMemberQuery: string = `query ($memberId: ID!) {
    member(memberId: $memberId) {
      id
      firstName
      lastName
      phoneNumber
      email,
      credits,
      localeCurrency
    }
  }`;

export async function getMember(memberId: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(getMemberQuery, { memberId });
    return res.data.member;
}
