import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { getMember } from "../api";
import { MemberValue } from "../types";

export function select(memberId: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        const memberInfo = getState().member.info;
        if (!!memberInfo && memberInfo.status === "loaded" && memberInfo.data && memberInfo.data.id === memberId)
            return;

        dispatch(actionCreators.infoLoading());

        try {
            const result: MemberValue = await getMember(memberId);
            dispatch(actionCreators.infoLoaded(result));
        } catch (e) {
            dispatch(actionCreators.infoFailed(e.message));
        }
    };
}
