import { AppDispatch } from "app/store";
import { actionCreators } from "../reducers";
import { MemberGroupTabResult } from "../types";
import { fetchGroupTabs } from "../api/groupTabsApi";

export function getGroupTabs(memberId: string, skip: number, limit: number) {
    return async function (dispatch: AppDispatch) {
        dispatch(actionCreators.groupTabsLoading());

        try {
            const result: MemberGroupTabResult = await fetchGroupTabs(memberId, skip, limit);
            dispatch(actionCreators.groupTabsLoaded(result));
        } catch (e) {
            dispatch(actionCreators.groupTabsFailed(e.message));
        }
    };
}
