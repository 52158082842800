import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { RouteComponentProps, withRouter } from "react-router";
import { OrderItemsSection, Props } from "../components/OrderItemsSection";
import { getPartyMember, isSingleUser } from "../selectors";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        party: { party },
    } = state;
    if (party.status === "loaded") {
        const partyMember = getPartyMember(state);
        return {
            items: party.data.order?.items,
            locale: party.data.locale,
            currency: party.data.currency,
            total: party.data.order?.orderTotal,
            singleUser: isSingleUser(state),
            memberId: partyMember && partyMember.id,
        };
    } else {
        return {
            loading: party.status === "loading",
        };
    }
};

export const OrderItemsContainer = withRouter(connect(mapStateToProps)(OrderItemsSection));
