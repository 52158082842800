import React from "react";
import { reducer } from "./reducers";
import { Route } from "react-router";
import { FeatureConfiguration } from "app/featureConfig";
import { GroupTabsPageContainer } from "./containers/GroupTabsPageContainer";

export * from "./types";

export default function (config: FeatureConfiguration) {
    config.reducers.groupTab = reducer;

    config.routes.locationRoutes.push(
        <Route
            path={[
                "/:region/location/:location_id/:page?/grouptab/:grouptab_id",
                "/:region/member/:member_id/:page?/grouptab/:grouptab_id",
            ]}
            exact
            key="grouptabs"
            component={GroupTabsPageContainer}
        />
    );
}
