import React from "react";
import { LocationValue } from "../types";
import { Content, Section, Tabs, Text } from "sharedComponents";
import { LocationInfoContainer } from "../containers/LocationInfoContainer";
import { BreadcrumbContainer } from "features/breadcrumb/containers/BreadcrumbContainer";
import { LocationPartiesContainer } from "../containers/LocationPartiesContainer";
import { LocationFeaturesContainer } from "../containers/LocationFeaturesContainer";
import { ExternalLinkIcon } from "sharedComponents/assets/Icons";
import "../assets/LocationPage.scss";
import { region } from "features/region";
import { config } from "common/config";
import { LocationGroupTabsContainer } from "../containers/LocationGroupTabsContainer";

export interface Props {
    location?: LocationValue;
    activeTab: number;
    setActiveTab: (activeTab: number) => void;
}

const tabs = [
    {
        content: <LocationPartiesContainer />,
        label: "Orders",
    },
    {
        content: <LocationGroupTabsContainer />,
        label: "GROUP TABS",
    },
];

export const LocationPage = ({ location, activeTab, setActiveTab }: Props) => (
    <>
        <Content>
            <>
                <LocationInfoContainer />
                <LocationFeaturesContainer />
                <Section className={"location-page--cms-venue"}>
                    <a
                        href={`${config.REACT_APP_CMS}${region.getActiveRegion().id}/${location?.slug}`}
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        <Text mode={["link", "bold"]}>
                            open cms for this location &nbsp;
                            <ExternalLinkIcon />
                        </Text>
                    </a>
                </Section>
            </>

            <Section>
                <Tabs activeIndex={activeTab} tabPanes={tabs} onActiveTab={(index) => setActiveTab(index)} />
            </Section>
        </Content>
        <BreadcrumbContainer />
    </>
);
