import { ThunkApi } from "app/store";
import { actionCreators } from "../reducers";
import { getLocation } from "../api";
import { LocationValue } from "../types";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const select = createAsyncThunk<void, string, ThunkApi>("LOCATION/SELECT", async (locationId, thunkAPI) => {
    const locationInfo = thunkAPI.getState().location.info;
    if (!!locationInfo && locationInfo.status === "loaded" && locationInfo.data.id === locationId) return;

    thunkAPI.dispatch(actionCreators.infoLoading());

    try {
        const result: LocationValue = await getLocation(locationId);
        thunkAPI.dispatch(actionCreators.infoLoaded(result));
    } catch (e) {
        thunkAPI.dispatch(actionCreators.infoFailed(e.message));
    }
});
