import React, { CSSProperties } from "react";
import "./Section.scss";
import classNames from "classnames";

interface Props {
    style?: CSSProperties;
    className?: string;
    children: React.ReactNode | React.ReactNode[];
}

export const Section = ({ style, className, children }: Props) => (
    <div style={style} className={classNames("section", className)}>
        {children}
    </div>
);
