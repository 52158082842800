import { AppDispatch, AppState } from "app/store";
import { actionCreators } from "../reducers";
import { getRawGroupTab } from "../api";

export const rawGroupTab = (groupTabId: string, locationId: string) => {
    return async (dispatch: AppDispatch, getState: () => AppState) => {
        const groupTabInfo = getState().groupTab;
        if (groupTabInfo.status === "loaded" && groupTabInfo.data.id === groupTabId) return;

        dispatch(actionCreators.loadingJson());

        try {
            const result: string = await getRawGroupTab(groupTabId, locationId);
            dispatch(actionCreators.jsonLoaded(result));
        } catch (e) {
            dispatch(actionCreators.jsonFailed(e.message));
        }
    };
};
