import { AppState } from "app/store";
import { createSelector } from "@reduxjs/toolkit";
import { Party, PartyMember, PosOrderData, Refund } from "../types";
import { getPartyLocale } from "../utils/partyLocaleCache";
import { Loaded } from "common/loader";

const getParty = (state: AppState): Party =>
    state.party.party.status === "loaded" ? state.party.party.data : undefined;
const getMember = (state: AppState) => (state.member.info.status === "loaded" ? state.member.info.data : undefined);

export const isSingleUser = createSelector(getParty, (party) => !party || party.members.length < 2);

export const getPartyMember = createSelector(getParty, getMember, (party, member) =>
    party && party.members
        ? (member && party.members.find((m: PartyMember) => m.id === member.id)) || party.members[0]
        : undefined
);

export const getPartyLastRefund = createSelector(getParty, (party): Refund | undefined => {
    if (!party?.refunds?.length) return undefined;
    const refund: Refund = party.refunds[party.refunds.length - 1];
    if (!refund) return undefined;

    let status = refund.status;
    switch (refund.status) {
        case "complete":
            status = "Refunded";
            break;
        case "pending":
            status = "Pending";
            break;
        case "failed":
            status = "Failed";
            break;
    }
    return {
        ...refund,
        status,
    };
});

export const getBillSplit = createSelector(getParty, (party) => {
    const memberPayments: any[] = [];
    if (party) {
        party.members.forEach((member: PartyMember) =>
            member.payments
                .filter((p) => p.statusSeverity === "SUCCESS")
                .forEach((memberPayment) => {
                    const newPayment = {
                        id: member.id,
                        success: true,
                        originalData: member,
                        field1: member.displayName,
                        field2: member.id,
                        field3: memberPayment.transactionId,
                        field4: getPartyLocale(party.locale, party.currency).formatCurrency(
                            memberPayment.transactionTotal || 0
                        ),
                    };
                    memberPayments.push(newPayment);
                })
        );
    }

    return memberPayments;
});

export const getPartyPosOrderIds = createSelector(getParty, (party) =>
    party.posOrders.map((p) => p.posOrderId!).filter((id) => id)
);

export const getPosOrderData = (state: AppState): Loaded<PosOrderData> => state.party.posOrderData;
