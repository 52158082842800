import { AppDispatch, AppState } from "app/store";
import { actionCreators, PARTIES_FILTERS_KEY } from "../reducers";
import { LocationPartyResult, LocationPartiesFilter } from "../types";
import { fetchParties } from "../api/partiesApi";

export function getParties(locationId: string, skip: number, limit: number, searchOptions?: LocationPartiesFilter) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.partiesLoading());

        try {
            if (searchOptions) {
                window.sessionStorage.setItem(PARTIES_FILTERS_KEY, JSON.stringify(searchOptions));
            } else {
                window.sessionStorage.removeItem(PARTIES_FILTERS_KEY);
            }
            const result: LocationPartyResult = await fetchParties(
                locationId,
                skip,
                limit,
                searchOptions?.orderId || null,
                searchOptions?.dates?.from || null,
                searchOptions?.dates?.to || null,
                searchOptions?.active || false
            );
            dispatch(actionCreators.partiesLoaded(result));
        } catch (e) {
            dispatch(actionCreators.partiesFailed(e.message));
        }
    };
}
