import { Action } from "@reduxjs/toolkit";
import { AppDispatch, AppMiddleware } from "app/store";
import { auth } from "common/auth";
import { region } from ".";
import { actionCreators } from "./reducers/region";
import { actionCreators as availableRegionActions } from "./reducers/regions";

function handleRegion(store: AppMiddleware) {
    var availableRegions = auth.getAvailableRegions();

    setTimeout(async () => {
        store.dispatch(actionCreators.loadingRegion("loading"));

        try {
            await region.init(auth.getAvailableRegions());

            store.dispatch(actionCreators.regionChanged(region.getActiveRegion()));

            if (!availableRegions || !availableRegions.length) return;

            availableRegions.forEach(async (r) => {
                try {
                    const res = await region.fetchRegion(r);
                    store.dispatch(availableRegionActions.addRegion(res));
                } catch {}
            });
        } catch {
            store.dispatch(actionCreators.loadingRegion("failed"));
        }
    }, 0);
}

export const regionMiddleware = () => (store: AppMiddleware) => (next: AppDispatch) => {
    if (auth.isAuthenticated()) {
        handleRegion(store);
    }
    auth.onLogin(() => {
        handleRegion(store);
    });

    return (action: Action<any>) => {
        next(action);
    };
};
