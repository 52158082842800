import React from "react";
import { formatTime, formatDate } from "./helper";
import "./StartandEndDateFormatted.scss";

interface Props {
    start: number | string;
    end: number | string;
}

export const StartandEndDateFormatted: React.FC<Props> = ({ start, end }: Props) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const formattedDate = formatDate(startDate);
    const startTime = formatTime(startDate);
    const endTime = formatTime(endDate);

    return (
        <span className="start-and-end-date-formatted">
            {formattedDate} {startTime} - {endTime}
        </span>
    );
};
