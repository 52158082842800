import React from "react";
import { reducer } from "./reducers";
import { Route } from "react-router";
import { FeatureConfiguration } from "app/featureConfig";
import { MemberPageContainer } from "./containers/MemberPageContainer";

export * from "./types";

export default function (config: FeatureConfiguration) {
    config.reducers.member = reducer;

    config.routes.locationRoutes.push(
        <Route path="/:region/member/:member_id/:page?" exact key="member" component={MemberPageContainer} />
    );
}
