import React from "react";
import { Currency, Text, KeyValueRow } from "sharedComponents";

interface Props {
    label: string;
    value: number;
    percentage?: number;
    locale: string;
    currency: string;
    disabled?: boolean;
    formatOptions?: { [key: string]: string | number };
}

export const PercentageKeyValueRow = ({
    label,
    value,
    percentage,
    locale,
    currency,
    disabled,
    formatOptions = {},
}: Props) => (
    <KeyValueRow
        disabled={disabled}
        item={{
            label: (
                <>
                    {label}
                    {!!percentage && (
                        <Text>
                            &nbsp;(
                            {percentage.toLocaleString("default", {
                                style: "percent",
                                ...formatOptions,
                            })}
                            )
                        </Text>
                    )}
                </>
            ),
            value: <Currency locale={locale!} currency={currency!} value={value} />,
        }}
    />
);
