import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { SearchUsers, Props } from "../components/SearchUsers";
import { AppDispatch, AppState } from "app/store";
import { searchMember } from "../actions";
import { actionCreators } from "../reducers";
import {
    getMembersIdOptions,
    getMembersNameOptions,
    getMembersPhoneOptions,
    getMembersEmailOptions,
} from "../selectors";
import { withRouter, RouteComponentProps } from "react-router";
import { AutoCompleteOption } from "sharedComponents";
import { region } from "features/region";

interface OwnProps {
    onSelectComplete: () => void;
}

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        search: { values, searchKey },
    } = state;
    let options: AutoCompleteOption[];
    switch (searchKey) {
        case "name": {
            options = getMembersNameOptions(state);
            break;
        }
        case "phone": {
            options = getMembersPhoneOptions(state);
            break;
        }
        case "email": {
            options = getMembersEmailOptions(state);
            break;
        }
        default: {
            options = getMembersIdOptions(state);
        }
    }
    return {
        options,
        searchKey,
        loading: values.status === "loading",
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, onSelectComplete }: OwnProps & RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        search: (value: string, force?: boolean) => {
            if (value.length < 2) {
                dispatch(actionCreators.loaded([]));
                return;
            }
            // Search should happen only on demand and not on change
            force && dispatch(searchMember(value));
        },
        select: (value: string) => {
            onSelectComplete();
            history.push(`/${region.getActiveRegion().id}/member/${value}/1`);
        },
        setSearchKey: (key: string) => dispatch(actionCreators.setSearchKey(key)),
    };
};

export const SearchUsersContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchUsers));
