import React from "react";
import { Layout } from "antd";
import "./Page.scss";

interface Props {
    /**
     * children is expected to be an array of 2 - 3 reactNodes
     * 1st is header, seconde is content and if third exists it will be footer
     */
    children: [React.ReactNode, React.ReactNode, React.ReactNode?];
}

export const Page = ({ children }: Props) => (
    <Layout className="page">
        <header>{children[0]}</header>
        <Layout.Content>{children[1]}</Layout.Content>
        {children.length === 3 && <Layout.Footer>{children[2]}</Layout.Footer>}
    </Layout>
);
