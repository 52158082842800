import React, { useCallback, useEffect, useState } from "react";
import { MemberValue } from "../types";
import { Text, Section, Spinner } from "sharedComponents";
import { InfoSection } from "sharedComponents/dataDisplay/infoSection";
import { Item } from "sharedComponents/dataDisplay/item";
import "../assets/MemberInfoSection.scss";
import { Currency } from "sharedComponents/controls/currency";
import { NoData } from "features/party/components/NoData";
import { EMAIL_REGEX } from "features/search";
import { EditableItem } from "sharedComponents/dataDisplay/editableItem";

export interface Props {
    member?: MemberValue;
    loading: boolean;
    isGuest: boolean;
    isGroupTabAdmin: boolean;
    memberId: string;
    getMember: (memberId: string) => void;
    updateMemberEmail: (memberId: string, email: string) => void;
}

export const MemberInfoSection = ({
    member,
    loading,
    memberId,
    isGuest,
    isGroupTabAdmin,
    getMember,
    updateMemberEmail,
}: Props) => {
    const [error, setError] = useState<string | undefined>();

    useEffect(() => {
        !!memberId && getMember(memberId);
    }, [getMember, memberId]);

    const onUpdateEmail = useCallback(
        (value: string) => {
            if (value && value.length > 0 && !EMAIL_REGEX.test(value)) {
                setError("Invalid email");
                return;
            }
            updateMemberEmail(memberId, value);
        },
        [memberId, updateMemberEmail]
    );

    return (
        <Section className="member-info">
            {loading ? (
                <Spinner size="large" />
            ) : !member ? (
                <NoData title="Profile" overrideText={isGuest ? "Guest member" : "The member account has been deleted."} />
            ) : (
                <InfoSection title={isGroupTabAdmin ? "Group Tab Admin Profile" : "Profile"}>
                    <Item label="Name">
                        <Text preset="subtitle" mode="bold" value={`${member.firstName} ${member.lastName ?? ""}`} />
                    </Item>
                    <>
                        <Item label="Member ID" value={member.id} />
                        <EditableItem
                            label="Email"
                            onUpdate={onUpdateEmail}
                            error={error}
                            defaultValue={member.email}
                        />
                    </>
                    <>
                        <Item label="Phone" value={member.phoneNumber || ""} />
                        {member?.credits && member.credits > 0 ? (
                            <Item label="Credits">
                                <Text disabled mode="bold">
                                    <Currency
                                        locale={member.locale!}
                                        currency={member.currency!}
                                        value={member.credits || 0}
                                    />
                                </Text>
                            </Item>
                        ) : null}
                    </>
                </InfoSection>
            )}
        </Section>
    );
};
