import { opsApi } from "common/api";
import { MemberValue } from "features/member/types";

type GraphQLPayload = { updateMemberEmail: MemberValue };

export interface UpdateMemberEmailModel {
    memberId: string;
    email: string;
}

const updateMemberEmailQuery = `mutation ($memberProfileUpdate: MemberProfileUpdateInput!) {
    updateMemberEmail(member: $memberProfileUpdate) {
        id
        firstName
        lastName
        phoneNumber
        email
        credits
        localeCurrency
    }
  }`;

export async function updateMemberEmail(memberProfileUpdate: UpdateMemberEmailModel) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(updateMemberEmailQuery, { memberProfileUpdate });
    return res.data.updateMemberEmail;
}
