import React from "react";
import { useEffect } from "react";

export interface CallbackProps {
    handleAuthentication: () => void;
}

export const CallbackPage = ({ handleAuthentication }: CallbackProps) => {
    useEffect(() => {
        handleAuthentication();
    }, [handleAuthentication]);

    return <div>call back page</div>;
};
