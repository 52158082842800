import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Loaded, LoadStatus } from "common/loader";
import { Region } from "../types/region";

export type State = Loaded<Region>;

const initialState: State = {
    status: "unloaded",
};

export const regionSlice = createSlice({
    name: "region",
    initialState,
    reducers: {
        loadingRegion: (state: State, action: PayloadAction<LoadStatus>) => {
            state.status = action.payload;
            if (state.status === "failed") {
                state.error = "failed to load";
            }
        },
        regionChanged: (state: State, action: PayloadAction<Region>) => {
            state.status = "loaded";
            if (state.status === "loaded") {
                state.data = action.payload;
            }
        },
        setRegion: (state: State, action: PayloadAction<Region>) => {},
    },
});

export const actionCreators = regionSlice.actions;
export const reducer = regionSlice.reducer;
