import React, { useEffect, useState } from "react";
import { Collapse, Switch } from "antd";
import "antd/dist/antd.css";
import "./PayloadViewer.scss";
import { Text } from "../text";
import ReactJson from "react-json-view";
import XMLViewer from "react-xml-viewer";
import { Spinner } from "sharedComponents/controls";
import classNames from "classnames";

const { Panel } = Collapse;

interface Props {
    label?: string;
    loading?: boolean;
    onOpen?: () => void;
    data?: string | object;
    contentType?: string;
    toggle?: boolean;
}

const CODE_DARK_MODE = "CODE_DARK_MODE";

const isDark = JSON.parse(window.localStorage.getItem(CODE_DARK_MODE) || "false");

export const PayloadViewer: React.FC<Props> = ({ label, data, loading, onOpen, toggle, contentType }: Props) => {
    const [value, setValue] = useState(toggle === false ? ["jsonKey"] : [""]);
    const [codeFormat, setCodeFormat] = useState<"unknown" | "json" | "xml">("unknown");
    const [dark, setDark] = useState(!!isDark);

    useEffect(() => {
        if (contentType) {
            if (contentType.indexOf("json") !== -1) {
                setCodeFormat("json");
                return;
            }

            if (contentType.indexOf("xml") !== -1) {
                setCodeFormat("xml");
                return;
            }
        }

        setCodeFormat("unknown");
    }, [contentType]);

    const isOpen = () => {
        return !!data && (toggle === false || value[0] === "jsonKey");
    };

    const handleOnClick = () => {
        !data && onOpen && onOpen();
        if (isOpen()) {
            setValue([""]);
        } else {
            setValue(["jsonKey"]);
        }
    };

    const setDarkMode = (isDark: boolean) => {
        window.localStorage.setItem(CODE_DARK_MODE, JSON.stringify(isDark));
        setDark(isDark);
    };

    const formattedData = jsonFormatter(data);

    const buttonText = isOpen() ? "Hide" : "Click to reveal";

    return (
        <div className={classNames("json-viewer", dark && isOpen() && "dark")}>
            {label && <Text preset="info-title">{label}</Text>}
            <Collapse bordered={false} activeKey={!!data ? value : []}>
                {!!loading ? (
                    <Spinner size="large" />
                ) : (
                    <Panel key="jsonKey" header="" showArrow={false}>
                        <pre>
                            <span className="dark-mode">
                                <span>Dark: </span>
                                <Switch checked={dark} size="small" onChange={setDarkMode} title="Dark" />
                            </span>
                            <code>
                                {codeFormat === "json" &&
                                    (!!formattedData ? (
                                        <ReactJson
                                            src={formattedData}
                                            name={null}
                                            theme={dark ? "shapeshifter" : "shapeshifter:inverted"}
                                            displayDataTypes={false}
                                            indentWidth={2}
                                            collapsed={1}
                                            collapseStringsAfterLength={30}
                                        />
                                    ) : (
                                        "No Data"
                                    ))}
                                {codeFormat === "xml" && <XMLViewer xml={data} collapsible />}
                                {codeFormat === "unknown" && data}
                            </code>
                        </pre>
                    </Panel>
                )}
            </Collapse>
            {toggle !== false && (
                <div className="json-viewer__label" onClick={handleOnClick}>
                    <Text preset="body">{buttonText}</Text>
                </div>
            )}
        </div>
    );
};

const jsonFormatter = (data?: string | object) => {
    if (typeof data !== "string") return data;
    try {
        return JSON.parse(data);
    } catch (e) {
        return false;
    }
};
