import React from "react";
import { useEffect } from "react";
import { Spinner } from "sharedComponents";

export interface LoginProps {
    handleLogin: () => void;
}

export const LoginPage = ({ handleLogin }: LoginProps) => {
    useEffect(() => {
        handleLogin();
    }, [handleLogin]);

    return (
        <div className="loader__container loader__container--overlay">
            <Spinner />
        </div>
    );
};
