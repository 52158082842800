import { opsApi } from "common/api";
import { Party } from "../types";

type GraphQLPayload = { party: Party };

const getPartyQuery: string = `query ($partyId: ID!, $locationId: ID!) {
    party(partyId: $partyId, locationId: $locationId) {
        id
        location {
            id
            displayName
        }
        section {
            id
            displayName
        }
        service {
            id
            displayName
        }
        tableNumber
        closeReason
        closeReasonSeverity
        locale
        currency
        dateOpened
        dateSubmitted
        dateScheduled
        isTakeaway,
        order {
            items {
                displayName
                variantName
                member {
                    id
                    displayName
                }
                quantity
                unitPrice
                lineTotal
                sku
                modifiers {
                    displayName
                    sku
                    unitPrice
                    nestedModifiers {
                        displayName
                        unitPrice
                    }
                }
                failedToSubmit
                memberNotes
            }
            orderTotal
            subtotal
            taxTotals {
                taxClass
                taxRate
                total
                exclusive
            }
            surcharge {
                percentage
                total
            }
            discount
            split {
                individualTotal
                memberCount
            }
            membershipDiscount
            membershipLevelName
            membershipLevelId
            promotions {
                name
                amount
            },
            trayCharges {
                name
                amount
            }
        }
        posOrders {
			posOrderId
		}
        paymentsTotal
        refunds {
            refundId
            transactionTotal
            transactionId
            transactionLink
            error {
                code
                message
            }
            date
            status
            statusSeverity
        }
        members {
            id
            displayName
            
            payments {
                transactionTotal
                transactionId
                transactionLink
                error {
                    code
                    message
                }
                paymentMethod
                date
                status
                statusSeverity
            }
            
            paymentTotals {
                tips
                credits
                processingFee
                venueServiceFeePercentage
                venueServiceFeeAmount
                totalCharged
                totalRefunded
                totalOutstanding
                total
            }
        }
        groupTab {
            id
            tabName
            packageName
            owner
            gratuityFactor
        }
    }
  }`;

export async function getParty(partyId: string, locationId: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(getPartyQuery, { partyId, locationId });
    return res.data.party || {};
}
