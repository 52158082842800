import { Tooltip } from "antd";
import classNames from "classnames";
import React from "react";
import { ActiveIcon, AlertIcon, CrossIcon, IdleIcon, TickIcon } from "sharedComponents/assets/Icons";
import { LocationPartyData } from "..";
import "../assets/RowOrderId.scss";

interface Props {
    order?: string;
    orderData: LocationPartyData;
}

function getIcon(closeReason: string) {
    switch (closeReason) {
        case "":
        case "pending":
            return <ActiveIcon />;
        case "resolved by venue":
        case "accepted":
        case "completed":
            return <TickIcon />;
        case "cancelled":
        case "failed":
        case "rejected":
            return <AlertIcon />;
        case "left":
            return <CrossIcon />;
        case "timedout":
            return <IdleIcon />;
        default:
            return null;
    }
}

export const RowOrderId = ({ order, orderData }: Props) => {
    const errorText = orderData.hadProblem || orderData.closeReason;
    const title = orderData.hadWarning ? `${orderData.hadWarning} (${errorText})` : errorText;
    return (
        <div
            className={classNames("row-order-id", {
                error: !!orderData.hadProblem,
                warning: !!orderData.hadWarning,
            })}
        >
            <Tooltip overlayClassName="row-order-id__tooltip" title={title}>
                <span className="row-order-id__icon">
                    {getIcon(orderData.hadWarning || orderData.closeReason || "")}
                </span>
            </Tooltip>
            <Tooltip overlayClassName="row-order-id__tooltip" title={title}>
                <span className="row-order-id__order">{order}</span>
            </Tooltip>
        </div>
    );
};
