import { opsApi } from "common/api";
import { MemberGroupTabResult } from "features/member/types";

type GraphQLPayload = { memberGroupTabs: MemberGroupTabResult };

const getMemberGroupTabsQuery: string = `query ($memberId: ID!, $skip: Int!, $limit: Int!) {
    memberGroupTabs(memberId: $memberId, skip: $skip, limit: $limit) {
        totalCount
        data {
            id
            tabName
            locationId
            locationName
            creditsTotal
            locale
            currency
            dateOpened
            dateClosed
            status
            closeReason
        }
    }
  }`;
export async function fetchGroupTabs(memberId: string, skip: number, limit: number) {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(getMemberGroupTabsQuery, { memberId, skip, limit });
        return res.data.memberGroupTabs;
    } catch (e) {
        throw e;
    }
}
