import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { RouteComponentProps, withRouter } from "react-router";
import { select } from "../actions";
import { select as memberSelect } from "../../member/actions";
import { OrderDetailsSection, Props } from "../components/OrderDetailsSection";
import { getPartyMember, getPartyLastRefund } from "../selectors";
import { getMembersParties } from "../../member/selectors";
import { email } from "../actions/email";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        party: { party, email },
        member: { info },
    } = state;

    const partyMember = getPartyMember(state);
    const partyLastRefund = getPartyLastRefund(state);

    const partyId = match.params["party_id"];
    let locationId = match.params["location_id"];

    if (!locationId) {
        // must have got here from member parties
        const memberParties = getMembersParties(state);
        const summary = memberParties.find((p: any) => p.id === partyId);
        if (summary) {
            locationId = summary.originalData.locationId;
        }
    }

    return {
        party: party.status === "loaded" ? party.data : undefined,
        loading:
            party.status === "loading" ||
            (!locationId && state.member.parties.status === "loading") ||
            email.status === "loading",
        partyId,
        isGuest: !!(partyMember && !partyMember.displayName),
        memberId: partyMember && partyMember.id,
        memberEmail: info.status === "loaded" && info.data?.email,
        locationId,
        showEmailInvoice: !!(partyMember && !!partyMember.payments.length),
        refund: partyLastRefund,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, { match }: RouteComponentProps<any>): FunctionProps<Props> => {
    return {
        getParty: (locationId: string) => dispatch(select(match.params["party_id"], locationId)),
        getMembers: () => match.params["member_id"] && dispatch(memberSelect(match.params["member_id"])),
        onEmailInvoiceClick: (partyId: string, memberId: string) => {
            dispatch(email(partyId, memberId));
        },
    };
};

export const OrderDetailsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(OrderDetailsSection));
