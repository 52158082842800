import { AppDispatch, AppState } from "app/store";
import { getPosHar } from "../apis/getPosHar";
import { actionCreators } from "../reducers";



export function loadHar(operationId: string) {
    return async function (dispatch: AppDispatch, getState: () => AppState) {
        dispatch(actionCreators.harLoading());

        try {
            const result: {} = await getPosHar(operationId);
            dispatch(actionCreators.harLoaded(result));
        } catch (e) {
            dispatch(actionCreators.harFailed());
        }
    };
}
