import React from "react";
import { Section } from "sharedComponents";
import { Tabs } from "sharedComponents/dataDisplay/tabs";
import { RawPartyTabContainer } from "../containers/RawPartyTabContainer";
import { PosOrderDataTabContainer } from "../containers/PosOrderDataTabContainer";
import { useSelector } from "react-redux";
import { getLocationInfo } from "features/location/selectors";

export interface Props {}

export const PartyDiagnosticsSection = () => {
    useSelector(getLocationInfo);

    return (
        <Section>
            <Tabs
                tabPanes={[
                    {
                        content: <RawPartyTabContainer />,
                        label: "Party JSON",
                    },
                    {
                        content: <PosOrderDataTabContainer />,
                        label: "POS Order Data",
                    },
                ]}
            />
        </Section>
    );
};
