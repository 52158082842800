import React from "react";
import { Text } from "sharedComponents";
import "../assets/Breadcrumb.scss";

export interface Props {
    crumbs: (string | React.ReactNode)[];
}

export const Breadcrumb = ({ crumbs }: Props) => (
    <div className="breadcrumbs">
        {crumbs.map((crumb, index) => (
            <Text key={`crumb-${index}`} preset="header">
                {crumb}
            </Text>
        ))}
    </div>
);
