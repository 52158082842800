import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { LocationFeaturesSection, Props } from "../components/LocationFeaturesSection";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        location: { info },
    } = state;

    return {
        flags: info.status === "loaded" ? info.data.flags || [] : [],
        loading: info.status === "loading",
    };
};

export const LocationFeaturesContainer = withRouter(connect(mapStateToProps)(LocationFeaturesSection));
