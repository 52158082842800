import React from "react";
import { DatePicker } from "antd";
import moment from "moment";
import "./DateRange.scss";
import classNames from "classnames";

interface Props {
    onSelect?: (from: string, to: string) => void;
    className?: string;
    defaultValues?: [Date | string, Date | string];
    hidePresets?: boolean;
    disabledDates?: (currentDate: moment.Moment) => boolean;
}

export const dateTimeFormat = new Intl.DateTimeFormat("en-au", { year: "2-digit", month: "2-digit", day: "2-digit" });

// convert presentation format (YYYY-MM-DD) into expected data format (DD/MM/YY)
const parseDate = (dateString: string): string => {
    return moment(dateString, "YYYY-MM-DD").format("DD/MM/YY");
};

export const DateRange: React.FC<Props> = ({
    onSelect,
    className,
    defaultValues,
    hidePresets,
    disabledDates,
}: Props) => {
    function onDateRange(momentsArray: any, formatString: [string, string]) {
        onSelect && onSelect(parseDate(formatString[0]), parseDate(formatString[1]));
    }

    return (
        <DatePicker.RangePicker
            defaultValue={
                defaultValues ? [moment(defaultValues[0], "DD/MM/YY"), moment(defaultValues[1], "DD/MM/YY")] : undefined
            }
            className={classNames("date-range", className)}
            onChange={onDateRange}
            ranges={
                !hidePresets
                    ? {
                          Today: [moment(), moment()],
                          "Last Week": [moment().subtract(1, "w"), moment().endOf("day")],
                          "This Month": [moment().startOf("month"), moment().endOf("day")],
                          "Last Month": [moment().subtract(1, "months"), moment().endOf("day")],
                          "Last 90 Days": [moment().subtract(90, "d"), moment().endOf("day")],
                          "This Year": [moment().startOf("year"), moment().endOf("day")],
                      }
                    : {}
            }
            format="YYYY-MM-DD"
            disabledDate={(current) => !!disabledDates && disabledDates(current)}
        />
    );
};
