import { opsApi } from "common/api";
import { batchGraphQLQuery } from "./batching";

export async function graphQLQuery<T = any>(url: string, query: string, variables?: {}): Promise<GraphQLResult<T>> {
    const result = await batchGraphQLQuery(query, variables, (q, v) => invokeQuery(url, q, v));

    return result as GraphQLResult<T>;
}

async function invokeQuery(url: string, query: string, variables?: {}) {
    const headers: { [key: string]: any } = { "Content-Type": "application/json" };

    const response = await opsApi.fetch(url, {
        method: "POST",
        headers,
        body: JSON.stringify({ query, variables }),
    });

    if (!response.ok) {
        throw response;
    }

    const result = await response.json();

    if (result.errors && result.errors.length) {
        throw result;
    }

    return result;
}

export interface GraphQLResult<T> {
    data: T;
    errors?: GraphQLError[];
}

export interface GraphQLError {
    message: string;
}
