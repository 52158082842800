import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { LocationPage, Props } from "../components/LocationPage";
import { PageLoadingProps, withFailed } from "common/loader/withLoader";
import { RouteComponentProps, withRouter } from "react-router";
import { actionCreators } from "../reducers";

const mapStateToProps = (state: AppState): DataProps<PageLoadingProps<Props>> => {
    const {
        location: { info, locationTab },
    } = state;

    return {
        loadStatus: info.status,
        location: info.status === "loaded" ? info.data : {},
        activeTab: locationTab,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, match }: RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        setActiveTab: (activeTab: number) => {
            dispatch(actionCreators.setLocationTab(activeTab));
            history.push(`1`);
        },
    };
};
export const LocationPageContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(withFailed(LocationPage)));
