import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { getPartyPosOrderIds, getPosOrderData } from "../selectors";
import { getLocationInfo, getSupportedPosOperationMap } from "features/location/selectors";
import { posOrderData } from "../actions/posOrderData";
import { PosOrderDataTab, Props } from "../components/PosOrderDataTab";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const location = getLocationInfo(state);
    const posOrderIds = getPartyPosOrderIds(state);
    const isSupported = !!getSupportedPosOperationMap(state)["RawOrder"];

    const posOrderData = getPosOrderData(state);

    return {
        locationId: location?.id,
        posOrderIds,
        isSupported,
        posOrderData,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, { match }: RouteComponentProps<any>): FunctionProps<Props> => {
    return {
        getOrderData: (locationId: string, posOrderId: string, format?: string) =>
            dispatch(posOrderData(locationId, posOrderId, format)),
    };
};

export const PosOrderDataTabContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(PosOrderDataTab));
