import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { SearchLocations, Props } from "../components/SearchLocations";
import { AppDispatch, AppState } from "app/store";
import { searchVenue } from "../actions";
import { actionCreators } from "../reducers";
import { getLocationsOptions } from "../selectors";
import { withRouter, RouteComponentProps } from "react-router";
import { region } from "features/region";

interface OwnProps {
    onSelectComplete: () => void;
}

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        search: { values },
    } = state;
    const options = getLocationsOptions(state);
    return {
        options,
        loading: values.status === "loading",
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, onSelectComplete }: OwnProps & RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        search: (value: string, force?: boolean) => {
            if (value.length < 2) {
                dispatch(actionCreators.loaded([]));
                return;
            }
            // Search should happen only on demand and not on change
            force && dispatch(searchVenue(value));
        },
        select: (value: string) => {
            onSelectComplete();
            history.push(`/${region.getActiveRegion().id}/location/${value}/1`);
        },
    };
};

export const SearchLocationsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchLocations));
