import React, { useEffect } from "react";
import { Text, Section, Spinner, StartandEndDateFormatted, DateFormatted, Button } from "../../../sharedComponents";
import { InfoSection } from "../../../sharedComponents/dataDisplay/infoSection";
import { Item } from "../../../sharedComponents/dataDisplay/item";
import { NoData } from "../../party/components/NoData";
import { region } from "../../region";
import { GroupTab } from "../types";
import "../assets/DetailsSection.scss";

export interface Props {
    groupTab?: GroupTab;
    owner?: string;
    memberEmail?: string;
    loading: boolean;
    groupTabId?: string;
    locationId: string;
    getGroupTab: (locationId?: string) => void;
    onEmailInvoiceClick: (groupTabId: string, owner: string) => void;
}
export const DetailsSection = ({
    groupTab,
    owner,
    memberEmail,
    loading,
    groupTabId,
    locationId,
    getGroupTab,
    onEmailInvoiceClick,
}: Props) => {
    useEffect(() => {
        getGroupTab(locationId);
    }, [getGroupTab, groupTabId, locationId]);

    const onClick = () => {
        groupTabId && owner && memberEmail && onEmailInvoiceClick(groupTabId, owner);
    };

    return (
        <Section className="groupTab-details">
            {loading ? (
                <Spinner size="large" />
            ) : !groupTab ? (
                <NoData title={"GROUP TAB Details"} />
            ) : (
                <InfoSection title={"GROUP TAB Details"}>
                    <>
                        {groupTabId && owner && memberEmail && (
                            <Button onClick={onClick} className="groupTab-details__button--email">
                                Email invoice
                            </Button>
                        )}
                        <Item label="Tab ID">
                            <Text preset="subtitle" mode="bold" value={groupTab.id} />
                        </Item>
                    </>
                    <>
                        <Item label="Date and duration">
                            {groupTab.status.toLowerCase() === "closed" &&
                            !!groupTab.dateOpened &&
                            !!groupTab.dateClosed ? (
                                <Text mode="bold">
                                    <StartandEndDateFormatted start={groupTab.dateOpened} end={groupTab.dateClosed} />
                                </Text>
                            ) : (
                                groupTab.dateOpened && (
                                    <Text mode="bold">
                                        <DateFormatted value={groupTab.dateOpened} />
                                    </Text>
                                )
                            )}
                        </Item>
                        <Item label="Venue">
                            <Text mode={["link", "bold"]}>
                                <a href={`/${region.getActiveRegion().id}/location/${groupTab.locationId}/1`}>
                                    {groupTab.locationName}
                                </a>
                            </Text>
                        </Item>
                        <Item label="Group Tab name" value={groupTab.tabName} />
                    </>
                    <>
                        <Item label="Status">
                            <Text mode="bold" className={"groupTab-details--" + groupTab.status.toLowerCase()}>
                                <p>{groupTab.status.toLowerCase() === "open" ? "Active" : groupTab.status}</p>
                            </Text>
                        </Item>
                        <Item label="Customer ID">
                            <Text mode={["link", "bold"]}>
                                <a href={`/${region.getActiveRegion().id}/member/${owner}/1`}>{owner}</a>
                            </Text>
                        </Item>
                    </>
                </InfoSection>
            )}
        </Section>
    );
};
