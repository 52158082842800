import { opsApi } from "common/api";

type GraphQLPayload = { emailInvoice: boolean };

export interface UpdateMemberEmailModel {
    memberId: string;
    partyId: string;
}

const emailInvoiceQuery = `mutation($partyId: ID!, $memberId: ID!) {
    emailInvoice(partyId: $partyId, memberId: $memberId)
}`;

export async function emailInvoice(partyId: string, memberId: string) {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(emailInvoiceQuery, { partyId, memberId });
    return res.data.emailInvoice;
}
