// Declared in public/config.(env).js to avoid having to modify these scripts at
// deploy time, which messes with the source maps
const globalConfig: { [key: string]: string | undefined } = (window as any).meanduConfig;

export const config: AppConfiguration = {
    ...process.env,
    ...globalConfig,
} as any;

export interface AppConfiguration {
    REACT_APP_CMS: string;
    REACT_APP_OPS_API: string;
    REACT_APP_AUTH0_CLIENT_ID: string;
    REACT_APP_AUTH0_DOMAIN: string;
    REACT_APP_AUTH0_CALLBACK_URL: string;
    REACT_APP_LEGACY_REGION: string;
}
