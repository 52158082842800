import { opsApi } from "common/api";
import { PosOrderData } from "..";

type GraphQLPayload = { posOrderData: PosOrderData };

const getPartyQuery: string = `query ($locationId: ID!, $posOrderId: ID!, $format: String) {
    posOrderData(locationId: $locationId, posOrderId: $posOrderId, format: $format) {
      contentType
      data
    }
  }`;

export async function fetchPosOrderData(
    locationId: string,
    posOrderId: string,
    format?: string
): Promise<PosOrderData> {
    const res = await opsApi.graphQLQuery<GraphQLPayload>(getPartyQuery, { locationId, posOrderId, format });
    return res.data.posOrderData || JSON.stringify({ message: "Nothing to show" });
}
