import React, { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, AutoCompleteOption, Text } from "sharedComponents";
import { DropDown } from "sharedComponents/controls/dropDown";
import { MEANDU_ID } from "..";

export interface Props {
    searchKey: string;
    setSearchKey: (key: string) => void;
    locationOptions: AutoCompleteOption[];
    ordersOptions: AutoCompleteOption[];
    loading?: boolean;
    searchLocation: (value: string, force?: boolean) => void;
    searchOrders: (location: string, searchKey: string, value: string, force?: boolean) => void;
    select: (location: string, party: string) => void;
    resetOrders: () => void;
}

const OrdersSearchBy: {
    [key: string]: {
        key: string;
        display: string;
        placeholder: string;
    };
} = {
    transactionId: {
        key: "transactionId",
        display: "Transaction ID",
        placeholder: "Enter transaction ID",
    },
    partyId: {
        key: "partyId",
        display: "Party ID",
        placeholder: "Enter party ID",
    },
};

export const SearchOrders = ({
    searchKey,
    setSearchKey,
    searchLocation,
    searchOrders,
    select,
    locationOptions,
    ordersOptions,
    loading,
    resetOrders,
}: Props) => {
    const [error, setError] = useState<string | undefined>();
    const [location, setLocation] = useState<string>();
    const searchOrderRef = useRef<any>();

    useEffect(() => {
        if (!!location && !!searchOrderRef?.current) {
            (searchOrderRef.current! as HTMLElement)?.focus();
        }
    }, [location]);

    const onOrdersSearchInternal = useCallback(
        (value: string, force?: boolean) => {
            if (searchKey === OrdersSearchBy.partyId.key && value && !MEANDU_ID.test(value)) {
                setError("Invalid partyId, expected 24 alphanumeric character");
                return;
            } else {
                setError(undefined);
            }
            searchOrders(location!, searchKey, value, force);
        },
        [searchKey, location, searchOrders]
    );

    useEffect(() => {
        return () => setError(undefined);
    }, [searchKey]);

    return (
        <div className="search__body">
            <Text className="search__title" mode={["block", "bold"]} preset="subtitle" value="Search for orders" />
            <Text
                className="search__description"
                preset="body"
                mode="block"
                value="Find specific orders by searching Transaction ID or Party ID."
            />
            <Text className="search__by" preset="body" mode="block" value="Search by" />
            <DropDown
                options={[OrdersSearchBy.transactionId, OrdersSearchBy.partyId]}
                onSelect={(key: string) => {
                    setSearchKey(key);
                    resetOrders();
                }}
            />
            <Text
                className="search__additional"
                preset="body"
                mode="block"
                value={`Enter the location and ${OrdersSearchBy[searchKey]?.display} of the order you are searching for`}
            />
            <div className="search__inputs__two">
                <Autocomplete
                    className="search__inputs__left"
                    loading={loading}
                    options={locationOptions}
                    onSearch={(value, force) => searchLocation(value, force)}
                    onSelect={(value) => setLocation(value)}
                    placeholder={"Location name or slug"}
                />
                <Autocomplete
                    className="search__inputs__right"
                    loading={loading}
                    options={ordersOptions}
                    disabled={!location}
                    key={"orders-" + searchKey}
                    onSearch={onOrdersSearchInternal}
                    onSelect={(value) => select(location!, value)}
                    placeholder={
                        !location ? "Select location first" : OrdersSearchBy[searchKey]?.placeholder || "Search"
                    }
                    ref={searchOrderRef}
                    error={error}
                    onChangeFormatter={(value: string) => {
                        !value && setError(undefined);
                        return value;
                    }}
                />
            </div>
        </div>
    );
};
