import { ITelemetryItem } from "@microsoft/applicationinsights-core-js";
import { auth } from "common/auth/auth";

// Add user data to all App Insights tracking events
export const getUserTelemetryInitializer = () => (item: ITelemetryItem) => {
    const authState = auth.getState();
    item.data!.staff_name = authState?.name;

    return true;
};
