import { getPartyLocale } from "features/party/utils/partyLocaleCache";
import React from "react";

interface Props {
    currency: string;
    locale: string;
    value: number;
}

export const Currency: React.FC<Props> = ({ currency, locale, value }: Props) => {
    return <>{getPartyLocale(locale, currency).formatCurrency(value)}</>;
};
