import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { select, updateEmail } from "../actions";
import { MemberInfoSection, Props } from "../components/MemberInfoSection";
import { getPartyMember } from "features/party/selectors";
import { getGroupTabOwner } from "features/groupTabs/selectors";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        member: { info },
    } = state;

    const partyMember = getPartyMember(state);
    const groupTabOwnerId = getGroupTabOwner(state);

    return {
        member: info.status === "loaded" ? info.data : undefined,
        loading: info.status === "loading",
        isGuest: !!(partyMember && !partyMember.displayName && partyMember.id),
        memberId: match.params["member_id"] || (partyMember && partyMember.id) || (groupTabOwnerId && groupTabOwnerId),
        isGroupTabAdmin: groupTabOwnerId !== undefined,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, { match }: RouteComponentProps<any>): FunctionProps<Props> => {
    return {
        getMember: (memberId: string) => dispatch(select(memberId)),
        updateMemberEmail: (memberId: string, email: string) => dispatch(updateEmail(memberId, email)),
    };
};

export const MemberInfoContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberInfoSection));
