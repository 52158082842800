import { opsApi } from "common/api";
import { MemberPartyResult } from "features/member/types";

type GraphQLPayload = { memberParties: MemberPartyResult };

const getMemberQuery: string = `query ($memberId: ID!, $skip: Int!, $limit: Int!) {
    memberParties(memberId: $memberId, skip: $skip, limit: $limit) {
        totalCount
        data {
            id
            locationId
            locationName
            tableNumber
            closeReason
            hadProblem
            hadWarning
            amount
            locale
            currency
            date
            paymentMethod
        }
    }
  }`;

export async function fetchParties(memberId: string, skip: number, limit: number) {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(getMemberQuery, { memberId, skip, limit });
        return res.data.memberParties;
    } catch (e) {
        throw e;
    }
}
