import { opsApi } from "common/api";
import { LocationGroupTabResult } from "../types";
import moment from "moment";

type GraphQLPayload = { locationGroupTabs: LocationGroupTabResult };

const getLocationQuery: string = `query ($locationId: ID!, $skip: Int!, $limit: Int!, $ownerNameSearch: String, $dateFrom: String, $dateTo: String, $activeTabs: Boolean!) {
    locationGroupTabs(locationId: $locationId, skip: $skip, limit: $limit, ownerNameSearch: $ownerNameSearch, dateFrom: $dateFrom, dateTo: $dateTo, activeTabs: $activeTabs) {
        totalCount
        data {
            id
            tabName
            locationId
            owner
            ownerName
            creditsTotal
            locale
            currency
            dateOpened
            dateClosed
            status
            members{
                displayName
            }
        }
    }
  }`;

export const fetchGroupTabs = async (
    locationId: string,
    skip: number,
    limit: number,
    ownerNameSearch: string | null,
    dateFrom: string | null,
    dateTo: string | null,
    activeTabs: boolean
) => {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(getLocationQuery, {
            locationId,
            skip,
            limit,
            ownerNameSearch,
            dateFrom: formatDate(dateFrom),
            dateTo: formatDate(dateTo),
            activeTabs,
        });
        return res.data.locationGroupTabs;
    } catch (e) {
        throw e;
    }
};

const formatDate = (date: string | null) => {
    return date && date?.length > 0 ? moment(date, "DD/MM/YY").format("YYYY-MM-DD 00:00:00") : null;
};
