import React, { FC, forwardRef, ReactNode, Ref, useEffect, useState } from "react";
import { Input, Spinner, Text } from "..";
import { AutoComplete as AntAutoComplete } from "antd";
import classNames from "classnames";
import "antd/dist/antd.css";
import "./Autocomplete.scss";

interface Props {
    options: AutoCompleteOption[];
    onSelect: (value: string) => void;
    onSearch: (searchText: string, force?: boolean) => void;
    placeholder?: string;
    className?: string;
    loading?: boolean;
    debounceMS?: number;
    disabled?: boolean;
    clearOnSelect?: boolean;
    ref?: React.Ref<any>;
    onChangeFormatter?: (value: string) => string;
    searchOnType?: boolean;
    error?: string;
}

export interface AutoCompleteOption {
    id: string;
    value: string;
    extraValues?: string[];
    icon?: ReactNode;
}

const renderItem = ({ id, value, extraValues, icon }: AutoCompleteOption) => {
    return {
        value: id,
        label: (
            <div className="autocomplete__options">
                <div className="autocomplete__options__value">
                    <Text preset="header" mode="bold">
                        {value}
                    </Text>
                    {icon}
                </div>
                {extraValues?.length && (
                    <div>
                        {extraValues
                            .filter((val) => !!val)
                            .map((val, index) => {
                                return (
                                    <>
                                        <Text preset="body">{val}</Text>
                                        {index < extraValues!.length - 1 && (
                                            <span className="autocomplete__options__dot">•</span>
                                        )}
                                    </>
                                );
                            })}
                    </div>
                )}
            </div>
        ),
    };
};

export const Autocomplete: FC<Props> = forwardRef(
    (
        {
            className,
            options,
            loading,
            placeholder,
            onSearch,
            onSelect,
            debounceMS,
            disabled,
            clearOnSelect,
            onChangeFormatter,
            searchOnType,
            error,
        }: Props,
        ref: Ref<any>
    ) => {
        const [value, setValue] = useState("");
        const [searchVal, setSearchVal] = useState("");
        const [debounceTimer, setDebounceTimer] = useState(-1);
        const internalOnSearch = (searchText: string) => {
            if (debounceMS) {
                window.clearTimeout(debounceTimer);
                setDebounceTimer(
                    window.setTimeout(() => {
                        onSearch(searchText);
                        setSearchVal(searchText);
                        setDebounceTimer(-1);
                    }, debounceMS)
                );
            } else {
                onSearch(searchText);
                setSearchVal(searchText);
            }
        };
        const forceSearch = (searchText: string) => {
            setSearchVal(searchText);
            onSearch(searchText, true);
        };
        const onChange = (value: string) => {
            if (onChangeFormatter) {
                value = onChangeFormatter(value);
            }
            setValue(value);
            if (!value) {
                setSearchVal("");
            }
        };
        const internalOnSelect = (selectText: string) => {
            setSearchVal("");
            onSelect(selectText);
            if (clearOnSelect) {
                setValue("");
            } else {
                setValue(options.find((option) => option.id === selectText)?.value || value);
            }
        };
        const displayLoading = (options.length && debounceTimer > -1) || loading;

        useEffect(() => {
            return () => setSearchVal("");
        }, []);

        return (
            <AntAutoComplete
                className={classNames("autocomplete", className && className)}
                notFoundContent={
                    displayLoading ? <Spinner /> : !!searchVal && <Text preset="body">No results found</Text>
                }
                onSearch={searchOnType ? internalOnSearch : undefined}
                onSelect={internalOnSelect}
                onChange={onChange}
                value={value}
                disabled={disabled}
                onBlur={() => setSearchVal("")}
                onFocus={() => setSearchVal(value)}
                listItemHeight={displayLoading ? undefined : 54}
                dropdownClassName="autocomplete__dorpdown"
                options={displayLoading ? [] : options.map((option) => renderItem(option))}
                ref={ref}
                open={!error && !!searchVal}
            >
                <Input
                    allowClear
                    autoFocus
                    searchMode
                    onSelectHandle={forceSearch}
                    placeholder={placeholder}
                    error={error}
                    isError={!!error}
                />
            </AntAutoComplete>
        );
    }
);
