import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { RouteComponentProps, withRouter } from "react-router";
import { select } from "../actions";
import { DetailsSection, Props } from "../components/DetailsSection";
import { email } from "../actions/email";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        groupTab: { groupTab },
        member: { info },
    } = state;

    const groupTabId = match.params["grouptab_id"];
    let locationId = match.params["location_id"];

    return {
        groupTab: groupTab.status === "loaded" ? groupTab.data : undefined,
        loading: groupTab.status === "loading",
        groupTabId,
        owner: groupTab.data?.owner,
        memberEmail: info.status === "loaded" && info.data?.email,
        locationId,
    };
};

const mapDispatchToProps = (dispatch: AppDispatch, { match }: RouteComponentProps<any>): FunctionProps<Props> => {
    return {
        getGroupTab: (locationId?: string) => dispatch(select(match.params["grouptab_id"], locationId)),
        onEmailInvoiceClick: (groupTabId: string, ownerId: string) => {
            dispatch(email(groupTabId, ownerId));
        },
    };
};

export const DetailsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(DetailsSection));
