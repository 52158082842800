import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { TransactionTab, Props } from "../components/TransactionTab";

const mapStateToProps = (state: AppState): DataProps<Props> => {
    const {
        groupTab: { groupTab },
    } = state;

    if (groupTab.status === "loaded") {
        return {
            payments: groupTab.data.credits,
            locale: groupTab.data.locale,
            currency: groupTab.data.currency,
            loading: groupTab.status === "loading",
        };
    } else {
        return {
            loading: groupTab.status === "loading",
        };
    }
};
export const TransactionTabContainer = connect(mapStateToProps)(TransactionTab);
