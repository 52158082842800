import React from "react";
import { reducer } from "./reducers";
import { Route } from "react-router";
import { FeatureConfiguration } from "app/featureConfig";
import { PosHttpArchivePageContainer } from "./containers/PosHttpArchivePageContainer";

export default function (config: FeatureConfiguration) {
    config.reducers.instrumentation = reducer;

    config.routes.locationRoutes.push(
        <Route path="/:region/logs/http/:operationId" exact key="har" component={PosHttpArchivePageContainer} />
    );
}
