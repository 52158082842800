import { AppState } from "app/store";
import { PaymentMethod } from "common/types";
import { MemberGroupTabData, MemberPartyData } from "features/member/types";
import { getPartyLocale } from "features/party/utils/partyLocaleCache";

export const getMembersParties = (state: AppState) => {
    return state.member.parties.status === "loaded"
        ? state.member.parties.data.data.map((value: MemberPartyData) => ({
              id: value.id,
              originalData: value,
              success: value.closeReason === "completed",
              field1: value.locationName,
              field2: value.tableNumber,
              field3: value.date,
              field4:
                  value?.paymentMethod?.toUpperCase() === PaymentMethod.GROUP_TAB
                      ? "Group Tab"
                      : getPartyLocale(value.locale, value.currency).formatCurrency(value.amount || 0),
          }))
        : [];
};

export const getMembersGroupTabs = (state: AppState) => {
    return state.member.groupTabs.status === "loaded"
        ? state.member.groupTabs.data.data.map((value: MemberGroupTabData) => ({
              id: value.id,
              originalData: value,
              success: value.status.toLocaleLowerCase() === "closed",
              field1: value.locationName,
              field2: value.tabName,
              field3:
                  value.status.toLocaleLowerCase() === "closed"
                      ? value.dateOpened + "," + value.dateClosed
                      : value.dateOpened,
              field4: getPartyLocale(value.locale, value.currency).formatCurrency(value?.creditsTotal || 0),
          }))
        : [];
};
