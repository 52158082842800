import React from "react";
import { Props as ItemProps, Item } from "../item";
import "./KeyValueRow.scss";
import classNames from "classnames";

interface Props {
    item: ItemProps;
    className?: string;
    mode?: "emphasis" | "mild" | "red" | "green";
    disabled?: boolean;
}

export const KeyValueRow = ({ item: { label, value }, className, mode, disabled }: Props) => (
    <Item
        className={classNames("key-value-row", className, mode)}
        normal
        disabled={disabled}
        label={label}
        value={value}
    />
);
