import { connect } from "react-redux";
import { DataProps, FunctionProps } from "types/utils";
import { AppDispatch, AppState } from "app/store";
import { withRouter, RouteComponentProps } from "react-router";
import { getGroupTabs } from "../actions";
import { MemberGroupTabs, Props } from "../components/MemberGroupTabs";
import { getMembersGroupTabs } from "../selectors";
import { getGroupTabOwner } from "features/groupTabs/selectors";

const mapStateToProps = (state: AppState, { match }: RouteComponentProps<any>): DataProps<Props> => {
    const {
        member: { groupTabs, memberTab },
    } = state;
    const displayPage = match.params.page;
    const getGroupTabsData = getMembersGroupTabs(state);
    const groupTabOwnerId = getGroupTabOwner(state);

    return {
        groupTabs: getGroupTabsData,
        totalCount: groupTabs.status === "loaded" ? groupTabs.data.totalCount : 0,
        displayPage: displayPage > 0 ? displayPage * 1 : 1,
        loading: groupTabs.status === "loading",
        memberId: match.params["member_id"] || groupTabOwnerId,
        selectedGroupTab: match.params["grouptab_id"],
        activeTab: memberTab,
    };
};

const mapDispatchToProps = (
    dispatch: AppDispatch,
    { history, match }: RouteComponentProps<any>
): FunctionProps<Props> => {
    return {
        getMemberGroupTabs: (memberId: string, skip: number, limit: number) =>
            dispatch(getGroupTabs(memberId, skip, limit)),
        goToPage: (page: number) => history.push(`${page}`),
        showGroupTab: (groupTabId: string) =>
            match.params["grouptab_id"]
                ? history.replace(`${groupTabId}`)
                : history.push(`${match.params.page}/grouptab/${groupTabId}`),
    };
};

export const MemberGroupTabsContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(MemberGroupTabs));
