import { connect } from "react-redux";
import { DataProps } from "types/utils";
import { AppState } from "app/store";
import { PageLoadingProps, withFailed } from "common/loader/withLoader";
import { GroupTabsPage, Props } from "../components/GroupTabsPage";

const mapStateToProps = (state: AppState): DataProps<PageLoadingProps<Props>> => {
    const {
        groupTab: { groupTab },
    } = state;

    return {
        loadStatus: groupTab.status,
        noTransactions: groupTab.data?.credits?.length === 0,
    };
};

export const GroupTabsPageContainer = connect(mapStateToProps)(withFailed(GroupTabsPage));
