import { opsApi } from "common/api";
import { GroupTabSearchResult } from "../types";

type GraphQLPayload = { searchGroupTabs: { totalCount: number; data: GroupTabSearchResult[] } };

const getLocationQuery: string = `query ($locationId: ID!, $searchTerm: String!, $skip: Int!, $limit: Int!) {
    searchGroupTabs(locationId: $locationId, searchTerm: $searchTerm, skip: $skip, limit: $limit) {
        totalCount
        data {
            id
            dateOpened
            creditsTotal
            locale
            currency
            owner
            members {
                displayName
            }
        }
    }
  }`;

export async function searchGroupTabs(locationId: string, searchTerm: string) {
    try {
        const res = await opsApi.graphQLQuery<GraphQLPayload>(getLocationQuery, {
            locationId,
            searchTerm,
            skip: 0,
            limit: 7,
        });
        return res.data.searchGroupTabs.data;
    } catch (e) {
        throw e;
    }
}
